import React, { useRef, useState } from 'react'
import { createSearchParams, Link, useNavigate } from 'react-router-dom'
import UserLayout from '../../../components/Layout/UserLayout'
import useGetData from '../../../hooks/useGetData'
import Loader from '../../../components/Loader'
import useOutsideClick from '../../../hooks/useOutsideClick'
import Pagination from '../../../components/paginate/index'
import Moment from 'react-moment'

const getUpdateTypeColor = (type) => { 
  switch (type) {
    case "Earnings Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Company Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Initial Report":
      return 'text-green-600 border border-green-600 bg-green-100'
    case "General Updates":
      return 'text-pink-600 border border-pink-600 bg-pink-100'
    case "Stocktalk":
      return 'text-yellow-600 border border-yellow-600 bg-yellow-100'
    default:
      return 'text-purple-600 border border-purple-600 bg-purple-100'
  }
}

const getDaysAgo = (date) => {
  // let splitDate = date?.split('/')
  // let formattedDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`

  // var today = new Date();
  // var createdOn = new Date(formattedDate);
  // var msInDay = 24 * 60 * 60 * 1000;

  // createdOn.setHours(0, 0, 0, 0);
  // today.setHours(0, 0, 0, 0)

  // var diff = (+today - +createdOn) / msInDay

  // if (+diff > 7) {
  //   diff = formattedDate
  // } else {
  //   if (+diff > 1) {
  //     diff = diff + ' days ago'
  //   } else {
  //     diff = diff + ' day ago'
  //   }
  // }

  return null
}

const updatesInitialState = {
  limit: 10,
  pageNo: 0,
  searchQuery: '',
  upload_type_id: ''
}

const companyInitialState = {
  limit: 5,
  type: 'all'
}

const UpdatesBoard = () => {
  const navigate = useNavigate()
  const { getFormData, setGetFormData, handleGetDataList: updatesList } = useGetData(updatesInitialState, 'getAllCompaniesDocument', () => { })
  const { handleGetDataList: handleCompanyList } = useGetData(companyInitialState, 'getCompanyMarketPriceList')
  const [selectedItem, setSelectedItem] = useState('All')
  const [filterDropDown, setFilterDropDown] = useState(false)
  const filterRef = useOutsideClick(() => setFilterDropDown(false))
  const [updatesTypeOptions, setUpdateTypeOptions] = useState([])

  const handleItemClick = (name, value) => {
    setSelectedItem(name)
    setGetFormData({ ...getFormData, upload_type_id: value })
    setFilterDropDown(false)
  }

  const handleUpdateTypeSuccess = (data) => {
    let temp = data.data
    let tempArr = []
    temp.forEach(it => {
      let data = {
        value: it.upload_type_id,
        name: it.type,
        total: it.total
      }
      tempArr.push(data)
    });
    setUpdateTypeOptions(tempArr)
  }
  const { handleGetDataList: updateList } = useGetData({}, 'getUpdateType', handleUpdateTypeSuccess)

  const handlePageClick = (val) => {
    let data = {
      ...getFormData,
      pageNo: val.selected
    }
    setGetFormData(data)

  }

  return (
    <UserLayout>
      <div className='flex items-center justify-between'>
        <h1 className='font-bold text-start text-xl mb-4 mt-4'>Updates Board</h1>
        {/* <div className='relative' ref={filterRef}>
          <div className='flex items-center border py-1 px-2 rounded shadow-sm hover:cursor-pointer' onClick={() => setFilterDropDown(!filterDropDown)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4 mr-2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
            </svg>
            <p className='font-semibold'>Filter:</p>
            <p className='ml-2 hidden md:block'>{selectedItem}</p>
            <p className='ml-2 block md:hidden'>{selectedItem.length < 5 ? selectedItem : selectedItem.slice(0, 6) + '...'}</p>
            <span className='arrow-down ml-2 mt-[2px]'></span>
          </div>
          {
            filterDropDown && (
              <div className='absolute h-30 w-max right-0 bg-white mt-2 rounded shadow-xl border z-10 hover:cursor-pointer'>
                <div key={'all'} className='m-1 hover:cursor-pointer' onClick={() => handleItemClick('All', '')}>
                  <p className={`rounded-sm px-2 py-1  ${selectedItem === 'All' ? 'text-red-600 underline underline-offset-4' : 'hover:text-red-600'}`}>All</p>
                </div>
                {
                  updatesTypeOptions.map((item) => {
                    const { name, value } = item
                    return (
                      <div key={name} className='m-1 hover:cursor-pointer' onClick={() => handleItemClick(name, value)}>
                        <p className={`rounded-sm px-2 py-1  ${selectedItem === name ? 'text-red-600 underline underline-offset-4' : 'hover:text-red-600'}`}>{name}</p>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        </div> */}
      </div>
      <div className='flex w-full flex-col lg:flex-row-reverse '>
        <div className='w-full md:w-max lg:min-w-[20rem] lg:ml-4'>
          <div className='flex flex-col border rounded-md mb-4 h-max w-full lg:mt-0 shadow-sm lg:shadow-md bg-[#fcfcfc] p-1'>
            {
              updateList.isLoading ? (
                <Loader />
              ) : (
                <>
                  <div key={'all'} className='m-1 hover:cursor-pointer w-max' onClick={() => handleItemClick('All', '')}>
                    <p className={`rounded-full px-2 py-[0.2rem] text-sm  ${selectedItem === 'All' ? 'text-red-600 bg-red-100 border border-red-600' : 'hover:text-red-600'}`}>All</p>
                  </div>
                  {
                    updatesTypeOptions.map((item) => {
                      const { name, value, total } = item
                      return (
                        <div key={name} className='p-1 hover:cursor-pointer w-full flex justify-between items-center' onClick={() => handleItemClick(name, value)}>
                          <p className={`rounded-full px-2 py-[0.2rem] text-sm  ${selectedItem === name ? 'text-red-600 bg-red-100 border border-red-600' : 'hover:text-red-600'}`}>{name}</p>
                          <p className='mr-2 text-sm px-2 font-semibold rounded-full'>{total}</p>
                        </div>
                      )
                    })
                  }
                </>)
            }
          </div>
          <div className='hidden lg:flex flex-col border rounded-md shadow-sm lg:shadow-md bg-[#fcfcfc]'>
            <p className='px-3 py-2 border-b font-semibold'>Coverage</p>
            {
              handleCompanyList?.isLoading ? (
                <Loader />
              ) : (
                handleCompanyList?.data?.data?.map((item) => {
                  const { company_name, gain_and_loss, id } = item
                  return (
                    <div className='w-full flex justify-between items-top border-b'>
                      {
                        company_name ? (
                          <>
                            <p className='p-3 text-sm hover:cursor-pointer hover:underline underline-offset-1' onClick={() => navigate(`/stocks/${id}`)}>{company_name}</p>
                            <p className={`p-3  text-sm ${gain_and_loss?.includes('-') ? 'text-red-600' : 'text-green-600'}`}>{gain_and_loss}</p>
                          </>
                        ) : (
                          <>
                            <p className='p-3 text-sm text-red-500'>Upgrade</p>
                            <p className='p-3 text-sm text-red-500 blur-sm'>00%</p>
                          </>
                        )
                      }

                    </div>
                  )
                })
              )
            }
          </div>
        </div>
        <div className='w-full'>
          <div className='w-full h-max rounded-lg'>
            {
              updatesList?.isLoading ? (
                <div className='flex-1'>
                  <Loader />
                </div>
              ) : (
                updatesList?.data?.data?.length !== 0 ? (
                  updatesList?.data?.data?.map((it, idx) => {
                    const { type } = it
                    return (
                      <div
                        key={it.id}
                        className={`flex p-4 bg-[#fcfcfc] shadow-sm border mb-2 rounded`}
                      >
                        <div className='flex flex-col flex-1'>
                          {/* <a
                            href={it.document_id !== "" ? `/stocks/${it.company_id}` : '/membership'}
                            target='blank'
                            className={`${it.document_id !== "" ? '' : 'text-red-600'} hover:cursor-pointer hover:underline font-semibold  space-x-1`}
                          >
                            {it.document_id !== "" ? it.company_name : 'Upgrade'}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 ml-1 mb-1 stroke-blue-600 inline">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                  </svg>
                          </a> */}
                          {it?.company_id
                                    ? 
                                <div className='flex items-center flex-1 space-x-2'> 
                                    <a
                                      href={it.document_id !== "" ? `/stocks/${it.company_id}` : '/membership'}
                                      target='_blank'
                                      className={`${it.document_id ? '' : 'text-red-600'} hover:cursor-pointer hover:underline font-semibold  space-x-1`}
                                    >
                                      {it.document_id !== "" ? it.company_name || it.document_name : 'Upgrade'}
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 ml-1 mb-1 inline stroke-blue-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                      </svg>

                                    </a>
                                </div>
                                    : ''}
                          <p
                          // 'text-blue-600'
                            className={`
                            ${it.document_id !== "" ? `${type === 'General Updates' || type === 'Stocktalk' ? 'hover:cursor-pointer hover:underline font-semibold  space-x-1' : 'text-blue-600 text-sm font-medium'} '`  : 'text-red-600 blur-sm'} 
                                 hover:underline underline-offset-1 hover:cursor-pointer
                            `}
                            onClick={() => {
                              if (it.document_id !== "") {
                                navigate(`/update/${it.document_id}`)
                              } else {
                                console.log("membership")
                                navigate('/membership')
                              }
                            }}
                          >
                            {it.document_id !== "" ? it.document_name : 'Upgrade now to see the document'}
                          </p>
                          <p className={`${type === 'General Updates' || type === 'Stocktalk' ? 'text-blue-600 text-sm font-medium' : 'text-xs font-medium text-gray-400'}`}> 
                                <span className=''>{it.document_id ? it.description : 'Upgrade now to see the document'}</span>
                          </p>
                        </div>
                        <div className='flex flex-col items-end'>
                          <p className={`text-xs mb-1 md:mb-2 ${getUpdateTypeColor(type)} w-max h-max py-[0.2rem] px-2 rounded-full`}>{type}</p>
                          <p className='mr-2 text-xs text-slate-600'>
                            {/* {it.document_date ? getDaysAgo(it.document_date) : ''} */}
                          <Moment unix format='DD/MM/YYYY' date={it.document_date}/>
                          </p>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className='flex flex-1 justify-center items-center min-h-[15rem] rounded'>
                    <p className="text-black-400">No updates found...</p>
                  </div>
                )
              )
            }
          </div>
          <div className=" my-7">
            {!(updatesList?.isLoading) && <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(updatesList?.data?.total_count / getFormData.limit)} pageNo={updatesList?.data?.page_no} />}
          </div>
        </div>
      </div>
    </UserLayout>
  )
}

export default UpdatesBoard