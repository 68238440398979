import React from 'react'
import Layout from '../../../../components/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import useGetData from '../../../../hooks/useGetData';
import parse from 'html-react-parser';
import Loader from '../../../../components/Loader';
import { useState } from 'react';
import PDFViewer from '../../../../components/PDFViewer';

const initialUpdate = {
  id: '',
  document_name: '',
  url: '',
  date: '',
  document_type: '',
  report_type: '',
  download: '',
  share: '',
  type: '',
  uploaded_on: '',
  upload_type_id: '',
  blog: ''
}

const ViewUpdates = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [updateData, setUpdateData] = useState(initialUpdate)

  const handleIndividualUpdateSuccess = (data) => {
    const temp = data.data[0]
    setUpdateData(temp)
  }
  const { handleGetDataList: handleUpdate } = useGetData({ document_id: params.documentId }, 'getIndividualDocument', handleIndividualUpdateSuccess)

  return (
    <Layout>
      <div className='flex w-max mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
          <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
        </svg>
        <p className='ml-1'>Back</p>
      </div>
      <div id='report-blog' className=''>
        {
          handleUpdate.isLoading ? (
            <Loader />
          ) : (
            (updateData?.report_type).toLocaleUpperCase() === 'PDF' ? (
              <PDFViewer
                path={updateData?.url}
                share={updateData?.share}
                download={updateData?.download}
                updateId={updateData.id}
                filename={updateData?.document_name}
                companyName={updateData?.company_name}
              />
              
            ) : (
              <div className='no-tailwindcss-base'>
                {updateData?.blog && 
                parse(updateData?.blog) 
}  
              </div>
            )
          )
        }
      </div>
    </Layout>
  )
}

export default ViewUpdates