import { useEffect, useState, useRef } from "react";
import React from 'react';
import useGetData from "../../hooks/useGetData";
import moment from "moment";

import { useNavigate } from "react-router-dom";


const initailState = {
    type: "",
    searchQuery: '',
    limit: 10,
    pageNo: 0
}
const  MembershipCountdown = () => {
    const navigate = useNavigate();
    const handleClick =()=> navigate('/membership')
    const [timerDays, setTimerDays] = useState("00");
    const [timerHours, setTimerHours] = useState("00");
    const [timerMinutes, setTimerMinutes] = useState("00");
    const [timerSeconds, setTimerSeconds] = useState("00");

    const handleSuccess = (data) => {
        const { subscription_data } = data || {};
        startTimer(subscription_data);
    }
    const { handleGetDataList } = useGetData(initailState, 'subscription_reminder', handleSuccess, 'get')
    let interval = useRef();
    const startTimer = (subscription_data) => {
        const { subscription_end_date } = subscription_data || {};
        // let countdownDate1 = new Date(+subscription_end_date).getTime();
        const formattedDate = moment.unix(+subscription_end_date).utc().format('MMMM DD, YYYY HH:mm:ss');
        // console.log(formattedDate);
        const countdownDate = new Date(formattedDate).getTime();
        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0) {
                //stop our timer
                clearInterval(interval.current);
            } else {
                //update timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        }, 1000);

    };

    useEffect(() => {

        return () => {
            clearInterval(interval.current);
        }
    });
    return (<div className='relative lg:flex lg:items-center lg:justify-between'>
    {handleGetDataList?.data?.threshold_data[0]?.show_threshold &&
        <div className='lg:flex flex space-x-2 lg:relative items-center shadow-xl text-lg font-semibold bg-[#f8d3d2] text-red-600 justify-center py-2 w-full md:max-w-[1300px] lg:max-w-[1500px] xl:max-w-[1750px] relative mx-auto'>
            <p className="mb-1 lg:mb-none text-xs lg:text-sm">Your membership will expire in:</p>
            <div className="flex items-center">
                <section className="border-[0.1px] border-black rounded-md items-center flex py-1 px-2 lg:py-2 lg:px-3 font-normal text-sm divide-x divide-black">
                    <p className="w-7 gap-1 text-xs lg:text-sm font-semibold pr-1">{timerDays}&nbsp;</p>
                    <p className="pl-1.5 pr-1 pt-1 text-xs">days</p>
                </section>
            </div>
            <div className="absolute top-5 left-9 lg:left-auto lg:top-3 lg:right-16">
                <button onClick={handleClick} className="rounded-lg">
                    <p className="px-2 text-blue-800 hover:underline font-semibold text-xs">Renew Membership</p>
                </button>
            </div>
        </div>
    }
</div>
    )
}

export default MembershipCountdown