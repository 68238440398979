import React from 'react'
import { BsPatchCheckFill } from "react-icons/bs";
import { Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate()
  return (
    <div className="page-width px-5  items-center flex  flex-col py-8 lg:mt-40 lg:mb-20">
    <BsPatchCheckFill  className="text-blue-700 w-24 mx-auto h-24 p-2" />
    <h2 className="hero_head_title w-fit pt-3 lg:pb-0 mx-auto text-2xl lg:text-3xl">
      Payment successful
    </h2>
    <p className="text-center font-semibold lg:text-lg mt-4">
      Thank you for subscribing to Capital Ideas.
      
    </p>
    <button
      on
      className=" bg-black mt-3 text-white px-10 py-2 rounded border border-gray-300 text-sm uppercase tracking-widest font-semibold"
      onClick={() => navigate('/orders', { replace: true })}
    >
      Return to Orders
      
    </button>
    </div>
  )
}

export default Success