import React from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import logo from '../../capitalideas.png'
import Cookies from 'universal-cookie';

const cookies = new Cookies
const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = (e) => {
    cookies.remove("_pn_token", { path: '/' })
    cookies.remove("_name", { path: '/' })
    navigate('/signin');
  }

  return (
    <div className='fixed flex flex-col w-[250px] p-8 h-screen bg-[#ffebea]'>
      <img onClick={() => navigate('/company-master')} className='w-[175px]  mt-4 hover:cursor-pointer' src={logo} alt='logo' />
      <div className='mt-14 flex flex-col flex-1'>
        <NavLink to={'/company-master'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M10 2c-1.716 0-3.408.106-5.07.31C3.806 2.45 3 3.414 3 4.517V17.25a.75.75 0 001.075.676L10 15.082l5.925 2.844A.75.75 0 0017 17.25V4.517c0-1.103-.806-2.068-1.93-2.207A41.403 41.403 0 0010 2z" clipRule="evenodd" />
          </svg>
          <p className='ml-4'>Company Master</p>
        </NavLink>
        <NavLink to={'/industry-master'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M5.5 3A2.5 2.5 0 003 5.5v2.879a2.5 2.5 0 00.732 1.767l6.5 6.5a2.5 2.5 0 003.536 0l2.878-2.878a2.5 2.5 0 000-3.536l-6.5-6.5A2.5 2.5 0 008.38 3H5.5zM6 7a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
          </svg>
          <p className='ml-4'>Industry Master</p>
        </NavLink>
        <NavLink to={'/subscription-master'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM6 5.75A.75.75 0 016.75 5h6.5a.75.75 0 010 1.5h-2.127c.4.5.683 1.096.807 1.75h1.32a.75.75 0 010 1.5h-1.32a4.003 4.003 0 01-3.404 3.216l1.754 1.754a.75.75 0 01-1.06 1.06l-3-3a.75.75 0 01.53-1.28H8c1.12 0 2.067-.736 2.386-1.75H6.75a.75.75 0 010-1.5h3.636A2.501 2.501 0 008 6.5H6.75A.75.75 0 016 5.75z" clipRule="evenodd" />
          </svg>
          <p className='ml-4'>Subscription Master</p>
        </NavLink>
        <NavLink to={'/users'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
          </svg>
          <p className='ml-4'>Users</p>
        </NavLink>
        <NavLink to={'/email-builder'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke="currentColor" className="w-5 h-4 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>
          <p className='ml-4'>Email Builder</p>
        </NavLink>
        <NavLink to={'/disclaimer'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke="currentColor" className="w-5 h-4 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>
          <p className='ml-4'>Disclaimer Builder</p>
        </NavLink>
        <NavLink to={'/general-and-stock-talk'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>

          <p className='ml-4'>General/Stock-Talk</p>
        </NavLink>
        <NavLink to={'/banners'} className={({ isActive }) => `flex items-center mb-2 ${isActive ? 'text-[#fe100e] bg-red-100' : 'text-[#00000099] hover:bg-red-100 hover:text-[#fe100e]'}  p-2 rounded-md`} >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z" />
          </svg>
          <p className='ml-4'>Banner</p>
        </NavLink>
        <div onClick={handleLogout} className={'flex items-center mb-2 hover:bg-red-200 text-[#fe100e] hover:cursor-pointer mt-auto p-2 rounded-md'}>
          <svg pointerEvents="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path pointerEvents="none" d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
          </svg>
          <p className='ml-4'>Logout</p>
        </div>
      </div>
    </div>
  )
}

export default Sidebar