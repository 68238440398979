import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import axios from "../api/axios";
import Cookies from "universal-cookie";
import toast from 'react-hot-toast';

const cookies = new Cookies();

export const successToast = (mes) => toast.success(mes);
export const errorToast = (e) => toast.error(e);

const useAddData = (url, callback, invalidateKey, callbackError) => {
    const queryClient = useQueryClient()
    const [error, onError] = useState()
    const [success, onSuccess] = useState()
    const setDataToServer = useMutation((data) => setData(data), {
        onError: (err) => {
            onError(true)
            errorToast(err.response.data.message);
            callbackError(err.response.data)
        },
        onSuccess: (data) => {
            onSuccess(true)
            callback(data.message)
            successToast(data.message);
            queryClient.invalidateQueries(invalidateKey)
        }
    })
    const setData = async (getFormData) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in getFormData) {
            fd.append(key, getFormData[key]);
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: url,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', 'Content-Type': 'multipart/form-data' }
        })
        return response.data
    }
    return { setDataToServer, error, onError, success, onSuccess }
}

export default useAddData