import axios from '../api/axios'
import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function useDownloadFile({ url, filename,onSuccess, onError, withBlob = true }) {

    const reportDownload = useMutation((data) => downloadReports(data), {
        onSuccess: (data) => {
            let href;
            if(withBlob){
                href = URL.createObjectURL(new Blob([data.data]));
            }else{
                href = data.data.data // link
            }
            let a = document.createElement('a');
            a.href = href;
            a.download = filename;
            a.click();
            onSuccess?.(data)
        },
        onError: (err) => {
            onError?.(err)
        }
    })

    const downloadReports = async (data) => {
        let TOKEN_NAME = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in data) {
            fd.append(key, data[key]);
        }
        let res = await axios(
            {
                method: "post",
                url: `/${url}`,
                responseType: withBlob ? 'blob' : '',
                data: fd,
                headers: { Authorization: TOKEN_NAME ? `Bearer ${TOKEN_NAME}` : '' }
            })
        return res
    };


    return { reportDownload }
}

export default useDownloadFile