import { Navigate } from 'react-router-dom'
import Cookies from "universal-cookie";
import jwtDecode from 'jwt-decode';
const cookies = new Cookies();

function ProtectedRoutes({ children, ...restOfProps }) {
    let token = cookies.get("_pn_token");
    const { UserType } = restOfProps

    if (!token) {
        return <Navigate to="/signin" replace />;
    }else {
        let decoded = jwtDecode(token);
        if(decoded.role !== UserType){
            cookies.remove("_pn_token",{ path: '/' })
            cookies.remove("_name",{ path: '/' })
            return <Navigate to="/signin" replace />;
        }
        return children;
    }
}

export default ProtectedRoutes
