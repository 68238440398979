import React, { useRef, useState } from 'react'
import Layout from '../../components/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import useGetData from '../../hooks/useGetData'
import useAddData from '../../hooks/useAddData'
import FormElement from '../../components/Form/FormElement'
import EditorRC from '../../components/Editor/EditorRc'
import { Toaster } from 'react-hot-toast'
import Loader from '../../components/Loader'

const EditDisclaimer = () => {
  const navigate = useNavigate()
  let params = useParams();
  const disclaimerRef = useRef(null);
  const [disclaimerObj, setDiscliamerObj] = useState({
    signature_id: '',
    signature_type: '',
    content: ''
  })

  const handleFetchSuccess = (data) => {
    let temp = data?.data
    setDiscliamerObj({
      signature_id: temp?.signature_id,
      signature_type: temp?.signature_type,
      content: temp?.content
    })
  }

  const { handleGetDataList: disclaimerDetails } = useGetData({ signature_id: params.id }, 'getSignatureContent', handleFetchSuccess)
  const { setDataToServer } = useAddData('/updateSignature', () => { }, 'getSignatureContent')

  const handleEdit = (e) => {
    e.preventDefault()
    console.log("pressed")
    setDataToServer.mutate({
      signature_id: disclaimerObj['signature_id'],
      signature_type: disclaimerObj['signature_type'],
      content: disclaimerRef.current.getContent()
    })
  }

  return (
    <>
      <Layout>
        <div className='flex w-max mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px] ml-[-4px]">
            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
          </svg>
          <p className='ml-1'>Disclaimer Master</p>
        </div>
        <h1 className='text-xl font-bold'>Edit Disclaimer</h1>
        <form onSubmit={handleEdit}>
          <div className='mb-6 w-[15rem] mt-6'>
            <FormElement
              name="signature_type"
              type="text"
              value={disclaimerObj['signature_type']}
              label='Dislaimer Type'
              placeholder='Enter Disclaimer Type...'
              element='input'
              onChange={(e) => setDiscliamerObj({ ...disclaimerObj, signature_type: e.target.value })}
              required={true}
            />
          </div>
          <div className='mt-6'>
            <label htmlFor="subscription_id" className='block text-gray-700 text-sm font-bold mb-2'>Disclaimer</label>
            <EditorRC editorRef={disclaimerRef} value={disclaimerObj['content']} />
          </div>
          <button type='submit' className='h-max mt-6 w-max rounded-md px-3 py-2 text-white bg-[#fe100e] hover:cursor-pointer flex items-center'>Save {setDataToServer.isLoading && <Loader isInline={true} />}</button>
        </form>
      </Layout>
      <Toaster position="top-right" />
    </>

  )
}

export default EditDisclaimer