import React, { useState } from 'react'
import jwtDecode from 'jwt-decode';
import axios from '../../api/axios'
import { useMutation } from "@tanstack/react-query";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import FormElement from '../../components/Form/FormElement';
import { SIGNUP_DETAILS } from './authInputs'
import Cookies from 'universal-cookie';
import logo from '../../capitalideas.png'
import HeroPage from './HeroPage';


const userdata = {
  username: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  password: '',
  confirm_password: ''
}

const cookies = new Cookies;
const SignUp = () => {
  let token = cookies.get('_pn_token');
  const navigate = useNavigate()
  const [userData, setUserData] = useState(userdata);
  const [accepted, setAccepted] = useState(false)
  const [error, setError] = useState("");

  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
      ...userData,
      [e.target.name]: value
    }
    setUserData(data)
  }

  const handleAuth = useMutation((data) => handleFormSubmit(data), {
    onSuccess: (data) => {
      cookies.set("_name", data.data.name, { path: '/' })
      cookies.set("_fname", data.data.fname, { path: '/' })
      cookies.set("_pn_token", data.data.token, { path: '/' })
      navigate("/stocks", { replace: true });
    },
    onError: (res) => {
      setError(res.response.data.message);
    }
  })

  const handleFormSubmit = async (getFormData) => {
    let fd = new FormData()
    for (var key in getFormData) {
      fd.append(key, getFormData[key]);
    }
    const response = await axios({
      method: 'POST',
      url: '/registerUser',
      data: fd,
      // withCredentials: true, 
    })
    return response
  }

  const handleAuthSubmit = (e) => {
    e.preventDefault()
    setError("")
    if (userData['password'] !== userData['confirm_password']) {
      alert("Password did not match");
      return;
    }
    handleAuth.mutate(userData)
  }

  if (token) {
    let decoded = jwtDecode(token);
    if (decoded.role === 'admin') {
      return navigate("/company-master", { replace: true });
    } else {
      return navigate("/stocks", { replace: true });
    }
  }


  return (
    <div className='h-max lg:h-screen w-screen flex flex-col md:flex-row'>
      <div className='flex flex-col w-full h-max lg:flex-row lg:w-[75vw] lg:h-[70vh] lg:m-auto lg:border lg:border-solid lg:rounded-lg lg:shadow-lg'>
        <HeroPage />
        <div className='w-full lg:w-1/2 h-max px-4 md:px-12 m-auto py-12 lg:pt-0 md:py-0 md:my-auto'>
          <form onSubmit={handleAuthSubmit}>
            {/* <h2 className='font-bold mb-6'>SignUp</h2> */}
            {
              error !== "" ? <p className='text-[#fe100e] mb-4'>* {error}</p> : <></>
            }
            <div className='grid grid-cols-1 md:grid-cols-2 auto-rows-auto gap-3 lg:gap-3 lg:gap-x-6 '>
              {
                SIGNUP_DETAILS.map((input, idx) => {
                  const { name } = input;
                  return <FormElement
                    key={idx}
                    {...input}
                    value={userData[name]}
                    onChange={handleSetFormData}
                  />
                })
              }
            </div>
            <div className='flex items-center mt-4'>
              {/* Accept terms and condition */}
              <input type="checkbox" value={accepted} onChange={(e) => setAccepted(e.target.checked)} className="w-3 h-3" />
              <p className='ml-4 text-sm'>I have read and agreed to <span className='text-md mb-2 underline text-[#fe100e]'><a rel="noreferrer" href='https://capitalideaz.in/disclaimer-terms-condition/' target='_blank'>terms & condition</a></span></p>
              {/* add link to tnc */}
            </div>
            {
              handleAuth.isLoading ? (
                <button type='submit' className='w-full flex items-center justify-center bg-[#fe100e] text-white py-2 rounded-md mt-4 lg:mt-2'>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Signing Up...
                </button>
              ) : (
                <button type='submit' disabled={!accepted} className={`w-full text-white py-2 rounded-md mt-4 lg:mt-2 ${!accepted ? 'hover:cursor-not-allowed bg-red-200' : 'bg-[#fe100e]'}`}>Sign Up</button>
              )
            }
            <p className='mt-4 lg:mt-2'>Already have an account? <Link to="/signin" className='text-[#fe100e]  bg-red-100 pt-1 px-3 pb-1  rounded-md text-[15px] font-semibold'>Sign In Now</Link></p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUp