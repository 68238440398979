const IS_REQUIRED = true;

export const SIGNUP_DETAILS = [
    {
        name: 'first_name',
        placeholder: '',
        label: 'First Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'last_name',
        placeholder: '',
        label: 'Last Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'phone_number',
        placeholder: '',
        label: 'Phone Number',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'email',
        placeholder: '',
        label: 'Email',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'password',
        placeholder: '',
        label: 'Password',
        type: 'password',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'confirm_password',
        placeholder: '',
        label: 'Confirm Password',
        type: 'password',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },

]

export const USER_DETAILS = [
    {
        name: 'first_name',
        placeholder: '',
        label: 'First Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'last_name',
        placeholder: '',
        label: 'Last Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'phone_number',
        placeholder: '',
        label: 'Phone Number',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'email',
        placeholder: '',
        label: 'Email',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
];

export const SIGNIN_DETAILS = [
    {
        name: 'email',
        placeholder: '',
        label: 'Enter Email',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'password',
        placeholder: '',
        label: 'Password',
        type: 'password',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

export const FORGET_PASSWORD_DETAILS = [
    {
        name: 'email',
        placeholder: '',
        label: 'Enter Email',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

export const RESET_PASSWORD = [
    {
        name: 'password',
        placeholder: '',
        label: 'Enter Password',
        type: 'password',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'confirm_password',
        placeholder: '',
        label: 'Enter Confirm Password',
        type: 'password',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    }
]