import React from 'react'
import UserLayout from '../../components/Layout/UserLayout'

const UserGuide = () => {
  return (
    <UserLayout>
      <div className='text-justify max-w-3xl mx-auto'>
        <h1 className='font-bold text-start text-xl mb-4 mt-4'>User Guide</h1>
        <p className='mb-4'>Welcome to Capital Ideas,</p>
        <p className='mb-4'>We provide independent equity research service under the SEBI Research Analyst regulation. Here is the guide to use this service effectively for your own research/investment activities. Before proceeding further, we request you to once again go through our <a className='underline underline-offset-1' rel="noreferrer" href='https://capitalideaz.in/disclaimer-terms-condition/' target='_blank'>Disclaimer, Terms of use</a>, <a className='underline underline-offset-1' rel="noreferrer" href='https://capitalideaz.in/faqs/' target='_blank'>FAQ</a>, and <a className='underline underline-offset-1' rel="noreferrer" href='https://capitalideaz.in/privacy-policy/' target='_blank'>Privacy Policy</a>  </p>
        <p className='mb-4'>We provide Rating and Sizing Guide for each idea. User can combine both for creating the portfolio with the help of this service.<span className='font-semibold'> We change the ratings and sizing guides periodically depending on the stock price movement, company specific events and earnings.</span></p>
        <div className='my-3'>
          <p className='font-semibold text-lg mb-4'>Rating</p>
          <p className='mb-4'>We have either <span className='font-semibold'>Buy, Hold</span> or <span className='font-semibold'>Exit</span> rating for the stock idea based on the various parameters. You can consider stocks that has current Buy rating for your investment purpose.</p>
          <ul className='list-disc ml-4'>
            <li><span className='font-semibold'>Buy : </span>You can consider buying these at current price with suitable sizing guide</li>
            <li><span className='font-semibold'>Hold :</span> You can continue to hold these but avoid fresh buying at current prices</li>
            <li><span className='font-semibold'>Exit :</span> You can exit these stocks at current prices</li>
            <li><span className='font-semibold'>Exited stocks (Coverage closure)</span> : These are the stocks where we have recommended exit at profitable levels or booked a loss </li>
          </ul>
        </div>
        <div className='my-3'>
          <p className='font-semibold text-lg mb-4'>Sizing Guide</p>
          <p className='mb-4'>Sizing guide helps user to decide how much to allocate in each stock idea. These weights depend on various parameters such as valuation, sector outlook, company specific developments and our conviction levels. Generally, allocation lower than 3% can not move the needle at portfolio level but to start a position a user can consider starting at this weight. </p>
          <p className='mb-4'>We provide <span className='font-semibold'>Low, Medium</span> and <span className='font-semibold'>High</span> as sizing guide to our recommendations.</p>
          <ul className='list-disc ml-4'>
            <li><span className='font-semibold'>Low : </span>Up to 4%</li>
            <li><span className='font-semibold'>Medium :</span> Between 5-7%</li>
            <li><span className='font-semibold'>High :</span>  8% and above</li>
          </ul>
          <p className='my-4'>A user can consider above guide to construct a suitable portfolio of stocks with keeping in mind his/her own risk appetite and comfort level. A point to keep in mind is that low allocation at less than 2-3% at portfolio levels will be insignificant and may not move a portfolio meaningfully in the event of stock doing good. On the contrary, investor runs a risk of concentration at higher allocation levels beyond 10%.</p>
        </div>
        <div className='mt-6 italic'>
          <p className='font-semibold mb-4'>Note:</p>
          <p className=''>This is just a guide to better construct a portfolio and in no way a rule of any sort. A user should consider his/her own risk appetite, comfort levels, overall exposure to equity as an asset class and other financial consideration such as family insurance cover (term & health insurance), outstanding loan obligation of any kind, contingency planning etc. Incase needed, take a help of financial planner/advisor. </p>
        </div>
      </div>
    </UserLayout>
  )
}

export default UserGuide