import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FRONT_END_URL, MEDIA_PATH } from '../../api/axios'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from '@mui/material';
import { Toaster, toast } from 'react-hot-toast';

const PDFViewer = ({ path, share, download, updateId, filename, companyName }) => {
  const [shareBtnTextCopied, setShareBtnTextCopied] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(true)
  const [zoom, setZoom] = useState(50)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);



  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setPdfLoading(false)
  }

  const downloadPdf = () => {
    fetch(path).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = companyName + "_" + filename + '.pdf';
        alink.click();
      })
    })
  }

  const copyUrl = async () => {
    toast.success('Link Copied to your clipboard')
    setShareBtnTextCopied(true)
    setTimeout(() => {
      setShareBtnTextCopied(false)
    }, 4000);
  }

  return (
    <>
      <div className={`${!pdfLoading && 'bg-[#d9d9d9]'} border w-full min-h-[10rem] max-h-[100vh] lg:max-h-[130vh]`}>
        <Document file={path} onLoadSuccess={onDocumentLoadSuccess} className={'overflow-hidden'}>
          <div className='w-full bg-white border-b py-2 px-4 flex flex-row-reverse'>

            {
              download === 'true' && (
                <button onClick={downloadPdf} className='mx-2 flex items-center bg-gray-100 border border-gray-600 rounded  p-1'>
                  {/* <p className='mr-2 items-center text-sm text-gray-600'>Download</p> */}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-[0.15rem] text-gray-600">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                </button>
              )
            }
            {
              share === 'true' && (
                <CopyToClipboard onCopy={copyUrl} text={window.location.origin + '/update/' + updateId}>
                  <button className={`mx-2 flex items-center ${shareBtnTextCopied ? 'bg-green-100 border-green-600' : 'bg-gray-100 border-gray-600'} border rounded p-1`}>
                    {/* <p className={`mr-2 items-center text-sm ${shareBtnTextCopied ? 'text-green-600' : 'text-gray-600'}`}>{shareBtnTextCopied ? 'Copied To Clipboard' : 'Copy to clipboard'}</p> */}
                    {shareBtnTextCopied ? (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h- text-green-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 md:w-4 md:h-4 md:mx-[0.15rem] text-gray-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                      </svg>
                    )}
                  </button>
                </CopyToClipboard>
              )
            }

            <div className='flex p-1 items-center'>
              <p>Zoom:</p>
              <p onClick={() => setZoom(zoom - 5)} className='rounded-full h-max font-semibold border hover:cursor-pointer bg-gray-50 mx-2 px-2'>-</p>
              <p>{zoom * 2}%</p>
              <p onClick={() => setZoom(zoom + 5)} className='rounded-full h-max font-semibold border hover:cursor-pointer bg-gray-50 mx-2 px-[0.4rem]'>+</p>
            </div>
          </div>

          <div className='m-2 overflow-y-auto overflow-x-hidden max-h-[90vh] lg:max-h-[120vh]'>
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <div className='shadow-lg mx-auto ' style={{ maxWidth: `${zoom}rem` }}>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={4}
                  />
                </div>
              )
            )}
          </div>
        </Document>
      </div>
      <Toaster position="top-center" />
    </>
  )
}

export default PDFViewer