import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import Layout from '../../../components/Layout'
import useGetData from '../../../hooks/useGetData'
import UserDetail from './UserDetail'
import Pagination from '../../../components/paginate'
import useDownloadFile from '../../../hooks/useDownloadFile'
import { RxReload } from "react-icons/rx";
import {useNavigate} from 'react-router-dom'


const initailState = {
  type: "",
  searchQuery: '',
  limit: 10,
  pageNo: 0
}

const Users = () => {
  const navigate = useNavigate()
  const [isOpenUserModal, setIsOpenUserModal] = useState(false); 
  const [userData, setUserData] = useState(null)
  const { getFormData, setGetFormData, handleGetDataList } = useGetData(initailState, 'getUserList')
  const { reportDownload:downloadEntireReport } = useDownloadFile({
    url: 'user_report',
    filename: 'users-report.xlsx',
    withBlob: false
  })

  const handleSearchVal = (e) => {
    let value = e.target.value
    let data = {
      ...getFormData,
      [e.target.name]: value
    }
    setGetFormData(data)
  }

  const handleUserClick = (user) => {
    navigate(`/user-detail/${user.userId}`,{ 
      state: user 
    })
  }

  const handlePageClick = (val) => {
    let data = {
      ...getFormData,
      pageNo: val.selected
    }
    setGetFormData(data)
  }

  // const handleGenerateBill = (e, userId) => {
  //   e.stopPropagation();
  //   reportDownload.mutate({
  //     user_id: userId
  //   })
  // }

  const handleDownloadReport = () => {
    downloadEntireReport.mutate({})
  }


  return (
    <>
      <Layout>
        <div className='flex items-center justify-between mb-4'>
          <h1 className='font-bold text-start text-xl'>Users</h1>
          <button disabled={downloadEntireReport.isLoading} onClick={handleDownloadReport} className=' text-blue-600 font-semibold text-sm disabled:opacity-20'>
              <div className='flex items-center space-x-3'>
                <span>Download Report</span>
                {downloadEntireReport.isLoading && <RxReload className='animate-spin'/>}
              </div>
            </button>
          {/* <a href="/" target="_blank" rel="noopener noreferrer" className=' text-blue-600 font-semibold text-sm disabled:opacity-20'>Download Report</a> */}
        </div>
        <div className='mb-4 w-full'>
          <input onChange={handleSearchVal} name='searchQuery' className='w-[40%] border drop-shadow shadow-sm focus:border-white focus:outline-none focus:ring-1 focus:ring-red-300 rounded-md px-3 py-2' type='text' placeholder="Search..." />
        </div>
        {/* drop-shadow-md */}
        <div className='overflow-x-auto relative border  shadow-sm border-slate-30npm run 0 sm:rounded-lg mb-4'>
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-red-700 uppercase bg-red-100">
              <tr>
                <th scope="col" className="py-3 px-6 text-left">
                  Username
                </th>
                <th scope="col" className="py-3 px-6 text-center">
                  Name
                </th>
                <th scope="col" className="py-3 px-6 text-center">
                  Email
                </th>
                <th scope="col" className="py-3 px-6 text-center">
                  Type
                </th>
                <th scope="col" className="py-3 px-6 text-right">
                  Phone Number
                </th>
                <th scope="col" className="py-3 px-6 text-right">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                handleGetDataList?.isLoading ? (
                  <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                    <td className="py-4 px-7 text-center" colSpan={6}>
                      loading...
                    </td>
                  </tr>
                ) : (
                  handleGetDataList?.data?.data?.length !== 0 ? (
                    handleGetDataList?.data?.data?.map((c) => {
                      const { userId, username, name, email, subscription_type, phone_number,subscription_receipt } = c;
                      return (
                        <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50" key={userId} onClick={() => handleUserClick(c)}>
                          <th scope="row" className="py-3 px-6 font-medium text-gray-900 whitespace-nowrap text-white">
                            {username}
                          </th>
                          <td className="py-3 px-6 text-center">
                            {name}
                          </td>
                          <td className="py-3 px-6 text-center">
                            {email}
                          </td>
                          <td className="py-3 px-6 text-center">
                            <p className='px-4 py-1 w-fit m-auto'>{subscription_type}</p>
                          </td>
                          <td className="py-3 px-6 text-right">
                            {phone_number}
                          </td>
                          <td className="py-3 px-6 text-right" >
                            {/* {
                              subscription_receipt === "1" && (
                                <svg onClick={(e) => handleGenerateBill(e, userId)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 m-auto">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                              )
                            } */}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mx-auto">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                      <td className="py-4 px-7 text-center" colSpan={5}>
                        No Users found
                      </td>
                    </tr>
                  )
                )
              }
            </tbody>
          </table>
        </div>
        <div className=" my-7">
          {
            handleGetDataList.isFetched && (
              <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetDataList?.data?.total_count / getFormData.limit)} pageNo={handleGetDataList?.data?.page_no} />
            )
          }

        </div>
      </Layout>
      <Toaster position="top-right" />
      {/* {isOpenUserModal && <UserDetail data={userData} open={isOpenUserModal} setOpen={setIsOpenUserModal} />} */}
    </>
  )
}

export default Users