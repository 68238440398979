import { useQuery } from "@tanstack/react-query"; 
import { useState } from 'react'
import axios from "../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function useGetData(initialData, type, handleSuccess = () => { }, method='POST') {
    const [getFormData, setGetFormData] = useState(initialData)
    const handleGetDataList = useQuery([type, getFormData], () => getData(getFormData), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            handleSuccess(data)
        },

    })
    const getData = async (getFormData) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in getFormData) {
            fd.append(key, getFormData[key]);
        }
        const response = await axios({
            method: method,
            data: fd,
            url: `/${type}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { getFormData, setGetFormData, handleGetDataList }
}

export default useGetData