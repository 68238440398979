import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import Cookies from 'universal-cookie';
import { useMutation } from '@tanstack/react-query';
import { BASE_URL } from '../../api/axios';

const EditorRC = ({ editorRef, value }) => {
	return (
		<>
			<Editor
				tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
				onInit={(evt, editor) => editorRef.current = editor}
				initialValue={value}
				init={{
					promotion: false,
					selector: "#editor",
					plugins:
						"advcode advlist advtable anchor autocorrect autolink autosave casechange charmap checklist codesample directionality editimage emoticons export footnotes formatpainter help image insertdatetime link linkchecker lists media mediaembed mergetags nonbreaking pagebreak permanentpen powerpaste searchreplace table tableofcontents tinymcespellchecker typography visualblocks visualchars wordcount",
					toolbar:
						"undo redo spellcheckdialog  | blocks fontfamily fontsizeinput fontsizeselect fontsize | bold italic underline forecolor backcolor | link image | align lineheight checklist bullist numlist | indent outdent | removeformat typography",
					height: "700px",

					//HTML custom font options
					font_size_formats:
						"8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",

					toolbar_sticky: true,
					autosave_restore_when_empty: true,
					spellchecker_active: true,
					spellchecker_language: "en_US",
					spellchecker_languages:
						"English (United States)=en_US,English (United Kingdom)=en_GB,Danish=da,French=fr,German=de,Italian=it,Polish=pl,Spanish=es,Swedish=sv",
					typography_langs: ["en-US"],
					typography_default_lang: "en-US",
					content_style: `
            body {
              background: #fff;
            }
    
            @media (min-width: 840px) {
              html {
                background: #eceef4;
                min-height: 100%;
                padding: 0 .5rem;
              }
    
              body {
                background-color: #fff;
                box-shadow: 0 0 4px rgba(0, 0, 0, .15);
                box-sizing: border-box;
                margin: 1rem auto 0;
                max-width: 820px;
                min-height: calc(100vh - 1rem);
                padding:4rem 6rem 6rem 6rem;
              }
            }
          `,
					images_upload_handler: (blobInfo) => {
						return new Promise((success, failure) => {
							let cookies = new Cookies;
							let token = cookies.get("_pn_token")
							var xhr, formData;
							xhr = new XMLHttpRequest();
							xhr.withCredentials = false;
							xhr.open('POST', BASE_URL + 'uploadBlogImage');
							xhr.setRequestHeader('Authorization', 'Bearer ' + token);
							xhr.onload = function () {
								var json;
								if (xhr.status !== 200) {
									// console.log('HTTP Error: ' + xhr.status);
									return;
								}
								json = JSON.parse(xhr.responseText);

								if (!json || typeof json.message != 'string') {
									// console.log('Invalid JSON: ' + xhr.responseText);
									return;
								}

								// success(json.location);
								if (xhr.status === 200) {
									// this is callback data: url
									success(json.message)
								}
							};

							formData = new FormData();
							formData.append('userfile', blobInfo.blob(), blobInfo.filename());
							xhr.send(formData);
						});
					}
				}}

			/>
		</>
	)
}

export default EditorRC;