import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import FormElement from '../../../components/Form/FormElement'
import UserLayout from '../../../components/Layout/UserLayout'
import useAddData from '../../../hooks/useAddData'
import useGetData from '../../../hooks/useGetData'
import { USER_DETAILS } from '../../auth/authInputs'
import ChangePassword from './ChangePassword'
import useDownloadFile from '../../../hooks/useDownloadFile'

const userdata = {
  userId: '',
  username: '',
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  password: '',
  confirm_password: ''
}

const cookies = new Cookies;
const UserProfile = () => {
  const navigate = useNavigate()
  const [isOpenChagePassModal, setIsOpenChagePassModal] = useState(false);
  const [userData, setUserData] = useState(userdata)

  const { handleGetDataList: userProfile } = useGetData({}, 'getUserProfile')
  const { setDataToServer } = useAddData('/updateUser', () => { }, 'getUserProfile')
  const { reportDownload } = useDownloadFile({
    url: 'generate_receipt',
    filename: 'Receipt.pdf'
  })


  useEffect(() => {
    if (userProfile.isSuccess) {
      setUserData(userProfile?.data?.data[0]);
    }
  }, [userProfile.isSuccess])

  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
      ...userData,
      [e.target.name]: value
    }
    setUserData(data)
  }

  const handleLogout = (e) => {
    cookies.remove("_pn_token", { path: '/' })
    cookies.remove("_name", { path: '/' })
    navigate('/signin');
  }

  const handleCancelChanges = () => {
    setUserData(userProfile?.data?.data[0]);
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setDataToServer.mutate(userData);
    cookies.set("_name", userData?.first_name + " " + userData?.last_name)
  }

  const handleGenerateBill = () => {
    reportDownload.mutate({
      user_id: userData.userId
    })
  }

  return (
    <>
      <UserLayout>
        <div className='w-full md:max-w-[35rem] mx-auto'>
          <div className='flex w-max mb-4 md:mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate('/stocks')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
              <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
            </svg>
            <p className='ml-1 md:mb-[3px] text-sm hover:text-underline'>Back</p>
          </div>
          <div className='flex flex-row mb-6'>
            <h1 className='font-bold'>Profile</h1>
            <button type='button' onClick={() => setIsOpenChagePassModal(true)} className='rounded-full h-max py-1 px-2 ml-3 mt-[3px] md:ml-6 text-xs border border-red-500 text-red-500 bg-red-100'>Change Password</button>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className='grid auto-rows-auto gap-6 gap-x-6 mb-8'>
              {
                USER_DETAILS.map((input, idx) => {
                  const { name } = input;
                  return <FormElement
                    key={idx}
                    {...input}
                    value={userData[name]}
                    onChange={handleSetFormData}
                  />
                })
              }
            </div>
            {
              userProfile?.isFetched && userData.userId !== '' && (JSON.stringify(userProfile.data?.data[0]) !== JSON.stringify(userData)) && (
                <>
                  <div>
                    {
                      setDataToServer?.isLoading ? (
                        <div>
                          <button type='submit' className='flex flex-row py-2 px-3 w-max bg-red-500 text-white border-red-500 mr-4 hover:cursor-pointer border rounded text-sm mb-8 focus:none'>
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Saving...
                          </button>
                        </div>
                      ) : (
                        <>
                          <button type='submit' className='py-2 px-3 w-max bg-red-500 text-white border-red-500 mr-4 hover:cursor-pointer border rounded text-sm mb-8 focus:none'>Save</button>
                          <button type='button' className='py-2 px-3 w-max text-red-500 border-red-500 mr-4 hover:cursor-pointer border rounded text-sm mb-8 focus:none' onClick={handleCancelChanges}>Cancel</button>
                        </>
                      )
                    }
                  </div>
                </>
              )
            }
          </form>
          <h2 className='font-bold mb-6'>Your Subscription: {userProfile.isFetched ? userData['subscription_type'] : ""}</h2>
          <div className='grid overflow-hidden grid-cols-1  auto-rows-auto mb-8 gap-3 '>
            <div className='flex justify-between'>
              <p className='text-md font-medium'>Subscription Taken on: </p>
              <p>{userProfile.isFetched ? userData['subscription_start_date'] : ""}</p>
            </div>
            <div className='flex justify-between'>
              <p className='text-md font-medium'>Subscription Ends on: </p>
              <p>{userProfile.isFetched ? userData['subscription_end_date'] : ""}</p>
            </div>
          </div>
          {
            userData['subscription_type'] === 'Paid' && (
              <button onClick={handleGenerateBill} className="block py-2 px-3 rounded bg-red-500 w-max text-white mr-4 hover:cursor-pointer mb-8">Download Receipt</button>
            )
          }
        </div>
      </UserLayout>
      <Toaster position="top-right" />
      {isOpenChagePassModal && <ChangePassword open={isOpenChagePassModal} setOpen={setIsOpenChagePassModal} />}
    </>
  )
}

export default UserProfile