import React, { useState } from 'react'
import useGetData from '../../../hooks/useGetData';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import PDFViewer from '../../../components/PDFViewer';
import parse from 'html-react-parser';
import UserLayout from '../../../components/Layout/UserLayout';

const initialUpdate = {
  id: '',
  document_name: '',
  url: '',
  date: '',
  document_type: '',
  report_type: '',
  download: '',
  share: '',
  type: '',
  uploaded_on: '',
  upload_type_id: '',
  blog: ''
}


const getUpdateTypeColor = (type) => {
  switch (type) {
    case "Earnings Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Company Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Initial Report":
      return 'text-green-600 border border-green-600 bg-green-100'
    case "General Updates":
      return 'text-pink-600 border border-pink-600 bg-pink-100'
    case "Stocktalk":
      return 'text-yellow-600 border border-yellow-600 bg-yellow-100'
    default:
      return 'text-purple-600 border border-purple-600 bg-purple-100'
  }
}



const ViewUpdatesUser = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [updateData, setUpdateData] = useState(initialUpdate)

  const handleIndividualUpdateSuccess = (data) => {
    const temp = data.data[0]
    setUpdateData(temp)
  }
  const { handleGetDataList: handleUpdate } = useGetData({ document_id: params.updateId }, 'getIndividualDocument', handleIndividualUpdateSuccess)

  return (
    <UserLayout>

      <div id='report-blog' className=''>
        <div className='max-w-[50rem] mx-auto mb-5'>
          <div className='flex items-center space-x-2'>
            <button className='flex w-max items-center hover:underline rounded p-2' onClick={() => navigate(-1)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
              </svg>
              <span>Back</span>
            </button>
          </div>
        </div>
        <div className='max-w-[50rem] mx-auto mb-4 mt-8 pb-4 border-b border-gray-400'>
          <h1 className=' font-semibold text-gray-800 text-lg'>{updateData?.document_name}</h1>
          {/* {updateData?.company_name && 
          <a href="" className='text-xs bg-red-100 px-4 py-1 rounded-lg font-light italic inline-flex items-center space-x-2'>
            <span>
            {updateData?.company_name}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-3 h-3 mb-[2px] inline">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
          </a>} */}

          <div className='flex items-center'> 
            <span className={`text-xs w-max h-max py-[0.2rem] px-2 rounded-full ${getUpdateTypeColor(updateData?.type)}`}>{updateData?.type}</span>
            <span className='w-1 h-1 rounded-full bg-gray-400 inline-block mx-2'></span>
            <span className='text-xs'>{updateData?.date}</span>
            {updateData?.company_name &&
              <>
                <span className='w-1 h-1 rounded-full bg-gray-400 inline-block mx-2'></span>
                <a href={updateData?.document_id !== "" ? `/stocks/${updateData?.company_id}` : '/membership'}className='text-xs font-semibold italic inline-flex items-center space-x-2 text-red-600'>
                  <span>
                    {updateData?.company_name}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-3 h-3 mb-[2px] inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                  </svg>
                </a>
              </>}

          </div>
        </div>
        {
          handleUpdate.isLoading ? (
            <Loader />
          ) : (
            (updateData?.report_type).toLocaleUpperCase() === 'PDF' ? (
              <PDFViewer
                path={updateData?.url}
                share={updateData?.share}
                download={updateData?.download}
                updateId={updateData.id}
                filename={updateData?.document_name}
                companyName={updateData?.company_name}
              />
            ) : (
              <div className='max-w-[50rem] mx-auto no-tailwindcss-base'>
                {updateData?.blog && parse(updateData?.blog)}

              </div>
            )
          )
        }
        {
          handleUpdate.isError && (
            <div className='flex items-center justify-center w-full h-[15rem]'>
              <p
                onClick={() => navigate('/membership')}
                className='text-red-600 text-sm hover:cursor-pointer text-center px-10'
              >This document is not available for your plan. <span className='underline underline-offset-2'>Upgrade to view</span> </p>
            </div>
          )
        }
      </div>
    </UserLayout>
  )
}

export default ViewUpdatesUser