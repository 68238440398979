import React from 'react'
import logo from '../../assets/footer.png'
import { AiOutlineTwitter } from 'react-icons/ai'
import { BiLogoLinkedin } from 'react-icons/bi'
import { PiGoogleLogoLight } from 'react-icons/pi'

const Footer = () => {
    return (
        <footer className=' mt-12 bg-[#f2f3f6]'>
            <div className='max-w-7xl mx-auto'>
                <div className=' px-5  lg:px-12 pt-12 pb-5 space-y-4'>
                    <div className="lg:grid grid-cols-3 gap-x-16 space-y-6 lg:space-y-0">
                        <div>
                            <div className=' w-36 h-24'>
                                <img src={logo} alt="logo" className='w-full h-full' />
                            </div>
                            <div className='mt-6 space-y-1'>
                                <h2 className=' font-bold tracking-widest libre uppercase text-base'>Hitesh Patel </h2>
                                <p className=' font-semibold text-sm libre'>SEBI Registered Research Analyst</p>
                                <p className='libre text-sm'>SEBI Registration No INH000009612</p>
                            </div>
                        </div>
                        <div>
                            <h2 className=' font-semibold mb-2 libre text-sm'>Reach US</h2>
                            <div className=' space-y-4'>
                                <ul>
                                    <li className=' text-sm text-gray-700 libre'>
                                        <a href="mailto:info@capitalideaz.in">
                                            <span>info@capitalideaz.in</span>
                                        </a>
                                    </li>
                                    <li className=' text-sm text-gray-700 libre'>
                                        <a href="tel:+91 7506622757">+91 7506622757</a>
                                    </li>
                                </ul>

                                <p className=' text-sm text-gray-700 libre font-normal pr-16'>202, Suri Rajendra Tower, R K Singh Marg,Andheri East, Mumbai 400 069</p>
                            </div>
                        </div>
                        <div>
                            <h2 className=' font-semibold mb-2 libre text-sm'>More Information</h2>
                            <div className='libre'>
                                <ul className=' text-sm text-gray-700'>
                                    <li>
                                        <a href="https://capitalideaz.in/investor-chart/" target="_blank" rel="noopener noreferrer">
                                            Investor Charter
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://capitalideaz.in/investor-grievances/" target="_blank" rel="noopener noreferrer">
                                            Investor Grievance
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://capitalideaz.in/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://capitalideaz.in/disclaimer-terms-condition/" target="_blank" rel="noopener noreferrer">
                                            Disclaimer, Terms & Condition
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-start-2 col-span-2 pr-24 space-y-2 lg:!-mt-[5%]'>
                            <p className=' italic text-sm text-gray-700 font-normal libre'>
                                <em>
                                    “Registration granted by SEBI and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors”
                                </em>
                            </p>
                            <p className=' italic text-sm text-gray-700 font-normal libre'>
                                <em>“Investments in securities markets are subject to market risks, read all the related documents carefully before investing”</em>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' bg-[#fe100e] text-white  py-3'>
                <div className='max-w-7xl mx-auto px-5 lg:px-16'>
                    <div className='lg:flex items-center justify-between libre space-y-4 lg:space-y-0'>
                        <div className=' text-sm'>ⓒ 2022 Capital Ideas Analytics. All rights reserved. Developed by
                            <a href="https://idigitize.co/" target="_blank" rel="noopener nofollow" className='ml-1 hover:underline'>iDigitize </a>
                        </div>
                        <div className='flex items-center justify-center space-x-4'>
                            <a href="https://mobile.twitter.com/nirvanatrails" target="_blank" rel="noopener noreferrer nofollow">
                                <AiOutlineTwitter className=' w-6 h-6' />
                            </a>
                            <a href="https://www.linkedin.com/in/hiteshpatel11" target="_blank" rel="noopener noreferrer nofollow">
                                <BiLogoLinkedin className=' w-6 h-6' />
                            </a>
                            <a href="https://www.goodreads.com/user/show/52412715-hitesh-patel" target="_blank" rel="noopener noreferrer nofollow">
                                <PiGoogleLogoLight className=' w-6 h-6' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer