import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "../../api/axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();


const _handleCreateOrder = async (getFormData) => {
    let TOKEN = cookies.get('_pn_token');
    let fd = new FormData()
    for (var key in getFormData) {
        fd.append(key, getFormData[key]);
    }
    const response = await axios({
        method: 'post',
        data: fd,
        url: `/payments/verify`,
        headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response
}

const useVerifyOrder = ({
    onSuccess,
    onError
}) => {
    const queryClient = useQueryClient()
    let handleVerifyOrder = useMutation((data) => _handleCreateOrder(data), {
        onError: (err) => {
            onError?.(err)
        },
        onSuccess: (res) => {
            onSuccess?.(res)
            // window.location.assign('/')
        }
    })
    return { handleVerifyOrder }
}

export default useVerifyOrder