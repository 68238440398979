import React from 'react'
import Layout from '../../components/Layout'
import { Link, useNavigate } from 'react-router-dom';
import useGetData from '../../hooks/useGetData';
import useAddData from '../../hooks/useAddData';
import { Toaster } from 'react-hot-toast';

const Disclaimer = () => {
  const navigate = useNavigate()
  const { getFormData, setGetFormData, handleGetDataList } = useGetData({}, 'getSignature')
  const { setDataToServer } = useAddData('/deleteSignature', () => { }, 'getSignatureContent')

  const deleteDisclaimer = (id) => {
    setDataToServer.mutate({
      signature_id: id
    })
  }

  return (
    <>
      <Layout>
        <div className='flex items-center mb-4 justify-between'>
          <h1 className='font-bold text-start text-xl mb-4'>Disclaimer Master</h1>
          {/* <p onClick={() => setIsOpenAddModal(true)} className='h-max w-max rounded-md px-3 py-2 bg-[#ffebea] text-[#fe100e] hover:cursor-pointer'>Add</p> */}
          <Link to={'/disclaimer/add'} >
            <p className='h-max w-max rounded-md px-3 py-2 bg-[#ffebea] text-[#fe100e] hover:cursor-pointer'>Add</p>
          </Link>
        </div>
        {/* drop-shadow-md */}
        <div className='wrapper overflow-x-auto relative border shadow-sm sm:rounded-lg mb-4'>
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-red-700 uppercase bg-red-100">
              <tr>
                <th scope="col" className="sticky-col first-col outline outline-1 text-center outline-slate-200 bg-red-100 py-2 px-4">
                  ID
                </th>
                <th scope="col" className="py-3 px-4 text-center">
                  Type
                </th>
                <th scope="col" className="py-3 px-4 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                handleGetDataList?.isLoading ? (
                  <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                    <td className="py-4 px-7 text-center" colSpan={9}>
                      loading...
                    </td>
                  </tr>
                ) : (
                  handleGetDataList?.data?.data?.length !== 0 ? (
                    handleGetDataList?.data?.data?.map((c, idx) => {
                      const { signature_id, signature_type } = c;
                      return (
                        <tr className={`${idx % 2 === 0 ? 'bg-white' : 'bg-red-50'} border-b`} key={signature_id}>
                          <th scope="row" className={`sticky-col text-center first-col outline outline-1 outline-slate-200 ${idx % 2 === 0 ? 'bg-white' : 'bg-red-50'} py-2 px-4 font-medium text-gray-900 whitespace-nowrap text-white`}>
                            {idx + 1}
                          </th>
                          <th scope="row" className={`sticky-col text-center first-col outline outline-1 outline-slate-200 ${idx % 2 === 0 ? 'bg-white' : 'bg-red-50'} py-2 px-4 font-medium text-gray-900 whitespace-nowrap text-white`}>
                            {signature_type}
                          </th>
                          <th className='py-3 px-6 flex justify-center'>
                            <svg onClick={() => navigate(`/disclaimer/${signature_id}/edit`)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer ">
                              <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                              <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                            </svg>
                            <svg onClick={() => deleteDisclaimer(signature_id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer text-red-600">
                              <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clipRule="evenodd" />
                            </svg>
                          </th>
                        </tr>
                      )
                    })
                  ) : (
                    <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                      <td className="py-4 px-7 text-center" colSpan={9}>
                        No Disclaimers found
                      </td>
                    </tr>
                  )

                )
              }
            </tbody>
          </table>
        </div>
      </Layout>
      <Toaster position="top-right" />
    </>

  )
}

export default Disclaimer