import React, { useRef, useState } from 'react'
import Layout from '../../../components/Layout'
import UserSelect from '../../../components/UserSelect/UserSelect'
import Loader from '../../../components/Loader/index'
import useGetData from '../../../hooks/useGetData'
import EditorRC from '../../../components/Editor/EditorRc'
import useAddData from '../../../hooks/useAddData'
import { Toaster } from 'react-hot-toast'
import FormElement from '../../../components/Form/FormElement'
import { toast } from 'react-hot-toast'
import { Checkbox } from '@mui/material'
import DialogMessage from '../../../components/DialogMessage'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const initailState = {
  type: "",
  searchQuery: '',
}


const EmailSender = () => {
  const navigate = useNavigate()
  const [selectedUsers, setSelectedUsers] = useState([])
  const [customSaluation, setCustomSalution] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [subscriptionListChecked, setSubcriptionListChecked] = useState({})
  const [mailFrom, setMailFrom] = useState('')
  const [subject, setSubject] = useState('')
  const [disclaimerValue, setDisclaimerValue] = useState('')
  const [disclaimerOptions, setDisclaimerOptions] = useState([
    {
      name: 'test',
      value: 'value',
      content: ''
    }
  ])
  const [mailSuccessMessage, setMailSuccessMessage] = useState("")
  const [mailErrorMessage, setMailErrorMessage] = useState("")
  const [errorObj, setErrorObj] = useState(null)
  const [disableForm, setDisableForm] = useState(false)

  const editorRef = useRef(null);
  const disclaimerRef = useRef(null);


  const handleFetchSuccess = (data) => {
    let temp = data?.data
    let tempOptions = []
    temp.map((item) => {
      tempOptions.push({
        name: item.signature_type,
        value: item.signature_type,
        content: item.content
      })
      return 1
    })
    setDisclaimerOptions(tempOptions)
  }

  const mailSuccess = (message) => {
    setMailSuccessMessage(message)
  }

  const mailError = (data) => {
    setMailErrorMessage(data?.message)
    setErrorObj(data?.data)
  }

  const { handleGetDataList: subscriptionList } = useGetData({}, 'getSubscriptionList')
  const { handleGetDataList: userList } = useGetData(initailState, 'getUserList')
  const { setDataToServer: sendEmail } = useAddData('/sendEmail', mailSuccess, 'getCategoryList', mailError)
  const { getFormData, setGetFormData, handleGetDataList } = useGetData({}, 'getSignature', handleFetchSuccess)

  const handleSelectAll = (e) => {
    // check if custom user is selected
    if (selectedUsers.length > 0) {
      alert("Custom Users are selected. Remove custom users")
      return
    }

    setSelectAllChecked(!selectAllChecked)
    // check all subcription types
    const tempSubsListObj = structuredClone(subscriptionListChecked)
    if (e.target.checked) {
      Object.keys(tempSubsListObj).map((item) => { return tempSubsListObj[item]['checked'] = true })
    } else {
      Object.keys(tempSubsListObj).map((item) => { return tempSubsListObj[item]['checked'] = false })
    }
    setSubcriptionListChecked(tempSubsListObj)
  }

  const handleSelectType = (e, id) => {

    // check if custom user is selected
    if (selectedUsers.length > 0) {
      alert("Custom Users are selected. Remove custom users")
      return
    }

    const tempSubsListObj = structuredClone(subscriptionListChecked)
    if (e.target.checked) {
      tempSubsListObj[id]['checked'] = true
      // check if all types are selected
      let allSelected = true
      Object.keys(tempSubsListObj).forEach((item) => {
        if (!tempSubsListObj[item]['checked']) {
          return allSelected = false
        }
      })
      if (allSelected) setSelectAllChecked(true)
    } else {
      tempSubsListObj[id]['checked'] = false
      setSelectAllChecked(false)
    }
    setSubcriptionListChecked(tempSubsListObj)
  }

  const handleRemoveUser = (user) => {
    let tempUsers = selectedUsers
    let newUsers = tempUsers.filter(e => e !== user)
    setSelectedUsers(newUsers)
  }

  useEffect(() => {
    if (disableForm) {
      setInterval(() => {
        setDisableForm(false)
      }, 5000)
    }
  }, [disableForm])

  const handleSubmit = (e) => {
    e.preventDefault()
    setDisableForm(true)
    setErrorObj(null)

    if (disableForm) {
      return
    }

    if (!editorRef.current.getContent()) {
      setMailErrorMessage("Email Body Cannot Be Empty")
      return
    }


    let checkedSubs = Object.keys(subscriptionListChecked).filter((item) => subscriptionListChecked[item].checked === true)
    let data = {
      emailData: editorRef.current.getContent(),
      emailIds: JSON.stringify(selectedUsers),
      selectAll: selectAllChecked,
      subscriptionIds: JSON.stringify(checkedSubs),
      subject,
      disclaimer: disclaimerRef.current.getContent(),
      mail_from: mailFrom,
      salutation: customSaluation ? "YES" : "NO" // attach logic
    }
    sendEmail.mutate(data)
  }

  const handleDisclaimerTypeChange = (e) => {
    let name = e.target.value
    setDisclaimerValue(name)
    if (name !== "") {
      let signature = disclaimerOptions.filter((item) => item?.name === name)
      disclaimerRef.current.setContent(signature[0].content)
    }
  }

  let checkedSubs = Object.keys(subscriptionListChecked).filter((item) => subscriptionListChecked[item].checked === true)

  return (
    <>
      <Layout>
        <div>
          <h2 className='font-bold text-start mb-6'>Create a new Email</h2>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <div className='relative'>
              <label htmlFor="subscription_id" className='block text-gray-700 text-sm font-bold mb-2'>Send Email to thses users</label>
              <UserSelect
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                userList={userList?.data?.data}
                subscriptionList={subscriptionList?.data?.data}
                subscriptionListChecked={subscriptionListChecked}
                setSubcriptionListChecked={setSubcriptionListChecked}
                selectAllChecked={selectAllChecked}
                setSelectAllChecked={setSelectAllChecked}
                handleSelectAll={handleSelectAll}
                handleSelectType={handleSelectType}
                handleRemoveUser={handleRemoveUser}
              />
              <div className='flex flex-wrap mt-2 mb-2'>
                {
                  selectAllChecked && <div className='flex mt-2 bg-red-100 items-center rounded-full py-1 px-3 mr-3 border border-red-400'>
                    <p className='text-sm text-red-700 mr-1'>All</p>
                    <button type='button' onClick={(e) => handleSelectAll(e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3 bg-transparent mb-[-3px] ml-1 hover:cursor-pointer text-red-700">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                }
                {
                  Object.keys(subscriptionListChecked).map((item) => {
                    if (subscriptionListChecked[item]['checked']) {
                      return (
                        <div className='flex mt-2 bg-red-100 items-center rounded-full py-1 px-3 mr-3 border border-red-400'>
                          <p className='text-sm text-red-700 mr-1'>{subscriptionListChecked[item]['subscription_type']}</p>
                          <button type='button' onClick={(e) => handleSelectType(e, item)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3 bg-transparent mb-[-3px] ml-1 hover:cursor-pointer text-red-700">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      )
                    } else {
                      return <></>
                    }
                  })
                }
                {
                  selectedUsers.length !== 0 && (
                    selectedUsers.map((student) => {
                      return (
                        <div className='flex mt-2 bg-red-100 items-center rounded-full py-1 px-3 mr-3 border border-red-400'>
                          <p className='text-sm text-red-700 mr-1'>{student}</p>
                          <button type='button' onClick={() => handleRemoveUser(student)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3 bg-transparent mb-[-3px] ml-1 hover:cursor-pointer text-red-700">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      )
                    })
                  )
                }
              </div>
            </div>
            {/* Custom salutation -> add <br/> at the end before sending it to backend */}
            <div className='w-[15rem] mt-2 mb-4'>
              <div className='flex items-center justify-between'>
                <label htmlFor={'saluation'} className='block text-gray-700 text-sm font-bold'>Salutation</label>
                <Checkbox
                  size="small"
                  checked={customSaluation}
                  onChange={(e) => setCustomSalution(!customSaluation)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              {
                customSaluation && (
                  <input
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline '
                    value={
                      "Dear" +
                      (selectAllChecked || (Object.keys(subscriptionListChecked).length === 0 && subscriptionListChecked.constructor === Object) ? " {{ User }}" : "") +
                      ','}

                    disabled={true}
                  />
                )
              }
            </div>
            <div className=' w-[15rem]'>
              <FormElement
                name="mail_from"
                type="email"
                value={mailFrom}
                label='Sender'
                placeholder='Enter Sender e-mail...'
                element='input'
                onChange={(e) => setMailFrom(e.target.value)}
                required={true}
              />
            </div>
            <p className='text-red-600 text-sm mt-2'>{errorObj?.mail_from}</p>
            <div className='mt-4'>
              <FormElement
                name="subject"
                type="text"
                value={subject}
                label='Subject'
                placeholder='Enter Subject...'
                element='input'
                onChange={(e) => setSubject(e.target.value)}
                required={true}
              />

            </div>
            <div>
              <div>
                <label htmlFor={'saluation'} className='block text-gray-700 text-sm font-bold mb-2'></label>
              </div>
            </div>
            <div className='my-6'>
              <label htmlFor="subscription_id" className='block text-gray-700 text-sm font-bold mb-2'>Write Your Email below</label>
              <EditorRC editorRef={editorRef} />
            </div>
            <div className='w-[15.5rem]'>
              <FormElement
                name="disclaimer"
                value={disclaimerValue}
                label='Disclaimer Type'
                placeholder='Enter Subject...'
                element='select'
                optionValue={disclaimerOptions}
                onChange={handleDisclaimerTypeChange}
                required={true}
              />
            </div>
            <div className='mt-6'>
              <label htmlFor="subscription_id" className='block text-gray-700 text-sm font-bold mb-2'>Disclaimer</label>
              <EditorRC editorRef={disclaimerRef} />
            </div>
            <div>
              <button
                type='submit'
                disabled={disableForm || (!selectAllChecked && selectedUsers.length === 0 && checkedSubs.length === 0)}
                className='flex items-center h-max mt-6 w-max rounded-md px-3 py-2 text-[#ffebea] bg-[#fe100e] hover:cursor-pointer disabled:bg-red-200 disabled:cursor-not-allowed'>Send Email {sendEmail.isLoading && <Loader isInline={true} />}</button>
            </div>
          </form>
        </div>
      </Layout>
      {/* <Toaster position="top-right" /> */}
      <DialogMessage open={mailSuccessMessage} type={"Success"} message={mailSuccessMessage} handleClose={() => { setMailSuccessMessage(''); navigate(-1) }} />
      <DialogMessage open={mailErrorMessage} type={"Error"} message={mailErrorMessage} handleClose={() => { setMailErrorMessage(''); }} />
    </>
  )
}

export default EmailSender