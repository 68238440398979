const IS_REQUIRED = true;

const ratingList = [
    { name: 'Buy', value: 'Buy' },
    { name: 'Hold', value: 'Hold' },
    { name: 'Exit', value: 'Exit' },
]

const reportTypes = [
    { name: 'Blog', value: 'BLOG' },
    { name: 'PDF', value: 'PDF' },
    { name: 'PPT', value: 'PPT' },
]


export const COMPANY_DETAILS = [
    {
        name: 'company_name',
        placeholder: 'Enter Company Name',
        label: 'Company Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'company_description',
        placeholder: 'Enter Company Description',
        label: 'Company Description',
        type: 'text',
        element: 'textarea',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'short_code',
        placeholder: 'Enter Short Code',
        label: 'Short Code',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'bse_code',
        placeholder: 'Enter Bse Code',
        label: 'BSE Code',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'nse_code',
        placeholder: 'Enter Nse Code',
        label: 'NSE Code',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'average_buy_price',
        placeholder: 'Enter Average Price',
        label: 'Avg. Buy Price',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    // {
    //     name: 'industry',
    //     placeholder: 'Enter Industry',
    //     label: 'Industry',
    //     type: 'text',
    //     element: 'input',
    //     required: IS_REQUIRED,
    //     errorMessage: 'This Field Cannot Be Empty !',
    // },
    {
        name: 'sizing_guide',
        placeholder: 'Enter Sizing Guide',
        label: 'Sizing guide',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'rating',
        placeholder: 'Enter Rating',
        label: 'Rating',
        type: 'text',
        element: 'select',
        optionValue: ratingList,
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'company_link',
        placeholder: 'Enter Company Link',
        label: 'Company Link',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'recommend',
        placeholder: 'Select Recommend Type',
        label: 'Recommend',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Yes', value: "1" },
            { name: 'No (Users would only be able to its updates.)', value: "2" },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

export const SMART_RATING = [
    {
        name: 'rating',
        placeholder: 'Enter Rating',
        label: 'Rating',
        type: 'text',
        element: 'select',
        optionValue: ratingList,
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

export const SMART_BUY = [
    {
        name: 'smart_buy',
        placeholder: 'Value at which to buy',
        label: 'Switch to buy',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

export const REPORT_TYPE = [
    {
        name: 'report_type',
        placeholder: 'Select Report',
        label: 'Report',
        type: 'text',
        element: 'select',
        optionValue: reportTypes,
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'download',
        placeholder: 'Enter Document Type',
        label: 'Downloadable',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Yes', value: 'true' },
            { name: 'No', value: 'false' },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'share',
        placeholder: 'Enter Document Type',
        label: 'Shareable',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Yes', value: 'true' },
            { name: 'No', value: 'false' },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

export const SMART_HOLD = [
    {
        name: 'smart_hold',
        placeholder: 'Value at which to hold',
        label: 'Switch to hold',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

// export const REPORT_FILE = [
//     {
//         name: 'report_file',
//         placeholder: 'Select File',
//         label: 'Upload',
//         type: 'file',
//         element: 'input',
//         required: IS_REQUIRED,
//         errorMessage: 'This Field Cannot Be Empty !',
//     }
// ]

export const DOCUMENT_DETAILS = [
    {
        name: 'document_name',
        placeholder: 'Enter Document Name',
        label: 'Document Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'document_type',
        placeholder: 'Enter Document Type',
        label: 'Document Type',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Active', value: 'Active' },
            { name: 'Hidden', value: 'Hidden' },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'download',
        placeholder: 'Enter Document Type',
        label: 'Downloadable',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Yes', value: 'true' },
            { name: 'No', value: 'false' },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'share',
        placeholder: 'Enter Document Type',
        label: 'Shareable',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Yes', value: 'true' },
            { name: 'No', value: 'false' },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'report_type',
        placeholder: 'Enter Report Type',
        label: 'Report Type',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Blog', value: 'BLOG' },
            { name: 'PDF', value: 'PDF' },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]

export const EDIT_DOCUMENT_DETAILS = [
    {
        name: 'document_name',
        placeholder: 'Enter Document Name',
        label: 'Document Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'document_type',
        placeholder: 'Enter Document Type',
        label: 'Document Type',
        type: 'text',
        element: 'select',
        optionValue: [
            { name: 'Active', value: 'Active' },
            { name: 'Hidden', value: 'Hidden' },
        ],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]