import React, { useState } from 'react'
import { useParams, useNavigate, Link, createSearchParams } from 'react-router-dom'
import UserLayout from '../../../components/Layout/UserLayout'
import StockChart from '../../../components/StockChart/StockChart'
import useGetData from '../../../hooks/useGetData'

const getRatingColor = (rating) => {
  if (rating === "Exit") {
    return "font-bold text-red-500"
  } else if (rating === "Buy") {
    return "font-bold text-green-500"
  } else if (rating === "Hold") {
    return "font-bold text-blue-500"
  }
}

const getUpdateTypeColor = (type) => { 
  switch (type) {
    case "Earnings Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Company Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Initial Report":
      return 'text-green-600 border border-green-600 bg-green-100'
    case "General Updates":
      return 'text-pink-600 border border-pink-600 bg-pink-100'
    case "Stocktalk":
      return 'text-yellow-600 border border-yellow-600 bg-yellow-100'
    default:
      return 'text-purple-600 border border-purple-600 bg-purple-100'
  }
}

const CompanyProfileUser = () => {
  let params = useParams();
  const navigate = useNavigate();

  const { getFormData: companyFormData, setGetFormData: setCompanyFormData, handleGetDataList: companyList } = useGetData({ id: params.companyId }, 'getCompanyDetails')
  const { getFormData: updatesFormData, setGetFormData: setUpdatesFormData, handleGetDataList: updatesList } = useGetData({ id: params.companyId }, 'getCompanyUploadList')
  const { handleGetDataList: companyHistoricalData } = useGetData({ company_id: params.companyId }, 'getHistoricalData')

  const formattedText = companyList?.data?.data?.[0].company_description?.replace(/\r\n|\r|\n/g, '<br>')
  return (
    <UserLayout>
      <div className='flex w-max mt-3 mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
          <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
        </svg>
        <p className='ml-1'>Company Master</p>
      </div>
      <div className='flex items-center mb-4 justify-between md:justify-start'>
        <div className='flex items-center'>
          <h1 className='text-xl font-bold mr-4'>{companyList?.data?.data?.[0].company_name}</h1>
        </div>
        <div className='flex self-baseline'>
          <h1 className='text-2xl font-bold whitespace-nowrap md:ml-8'>₹ {companyList?.data?.data?.[0]?.current_detail?.current_price}</h1>
          {/* {
              parseInt(companyList?.data?.data?.[0]?.current_detail?.gain_and_loss) > 0 ? (
                <div className="w-0 h-0 ml-2
                border-l-[4px] border-l-transparent
                border-b-[8px] border-b-green-500
                border-r-[4px] border-r-transparent
                 "></div>
              ):(
                <div className="w-0 h-0 ml-2
                border-l-[4px] border-l-transparent
                border-t-[8px] border-t-red-500
                border-r-[4px] border-r-transparent
                 "></div>
              )

            }
            <h3 className={`text-sm ml-1 ${parseInt(companyList?.data?.data?.[0]?.current_detail?.gain_and_loss) > 0 ? 'text-green-500':'text-red-500'}`}>{companyList?.data?.data?.[0]?.current_detail?.gain_and_loss}</h3> */}
        </div>
      </div>
      <div className='flex justify-start mb-4'  style={{ whiteSpace: 'pre-line' }}>
        {/* <p>{companyList?.data?.data?.[0].company_description}</p> */}
        
        <div dangerouslySetInnerHTML={{ __html: formattedText }} />
      </div>
      {/* <div className='w-full h-64 my-8 '>
        {
          companyHistoricalData?.isFetched ? (
            <StockChart data={companyHistoricalData?.data?.data} />
          ) : (
            <p>loading...</p>
          )
        }
      </div> */}
      <div className='grid overflow-hidden grid-cols-1 lg:grid-cols-2 auto-rows-auto mb-8 rounded border shadow'>
        <div className='flex justify-between rounded-sm md:px-8  bg-[#f9f9f9] md:bg-white'>
          <p className='text-md font-bold py-3 px-4'>BSE Code</p>
          <p className='text-right py-3 px-4 '>{companyList?.data?.data?.[0].bse_code}</p>
        </div>
        <div className='flex justify-between rounded-sm md:px-8'>
          <p className='text-md font-bold py-3 px-4'>NSE Code</p>
          <p className='text-right py-3 px-4'>{companyList?.data?.data?.[0].nse_code}</p>
        </div>
        <div className='flex justify-between rounded-sm md:px-8 bg-[#f9f9f9]'>
          <p className='text-md font-bold py-3 px-4'>Avg. Buy Price</p>
          <p className='text-right py-3 px-4'>{companyList?.data?.data?.[0].average_buy_price}</p>
        </div>
        {/* <div className='flex justify-between rounded-sm md:px-8'>
            <p className='text-md font-bold py-3 px-4'>Industry</p>
            <p className='text-right py-3 px-4'>{companyList?.data?.data?.[0].industry}</p>
          </div> */}
        <div className='flex justify-between rounded-sm md:px-8 md:bg-[#f9f9f9]'>
          <p className='text-md font-bold py-3 px-4'>Short Code</p>
          <p className='text-right py-3 px-4'>{companyList?.data?.data?.[0].short_code}</p>
        </div>
        <div className='flex justify-between rounded-sm md:px-8 bg-[#f9f9f9] md:bg-white'>
          <p className='text-md font-bold py-3 px-4'>Sizing Guide</p>
          <p className='text-right py-3 px-4'>{companyList?.data?.data?.[0].sizing_guide}</p>
        </div>
        <div className='flex justify-between rounded-sm md:px-8'>
          <p className='text-md font-bold py-3 px-4'>Current Market Cap.</p>
          <p className='text-right py-3 px-4'>{parseInt(companyList?.data?.data?.[0].current_detail?.market_cap).toLocaleString("en-US")}</p>
        </div>
        <div className='flex justify-between rounded-sm md:px-8 bg-[#f9f9f9]'>
          <p className='text-md font-bold py-3 px-4'>Industry</p>
          <p className='text-right py-3 px-4'>{companyList?.data?.data?.[0].category[0].label}</p>
        </div>
        <div className='flex justify-between rounded-sm md:px-8 md:bg-[#f9f9f9]'>
          <p className='text-md font-bold py-3 px-4'>Rating</p>
          <p className={`${getRatingColor(companyList?.data?.data?.[0].rating)} rounded text-right py-3 px-4`}>{companyList?.data?.data?.[0].rating}</p>
        </div>
        {
          companyList?.data?.data?.[0].company_link && (
            <div className='flex justify-between rounded-sm md:px-8 bg-[#f9f9f9] md:bg-white'>
              <p className='text-md font-bold py-3 px-4'>Company Link</p>
              <a href={companyList?.data?.data?.[0].company_link} className='text-right py-3 px-4 text-blue-600 hover:underline'>{companyList?.data?.data?.[0].company_link}</a>
            </div>
          )
        }
        {/* <div className='hidden lg:flex justify-between border border-r'>
          <p className='text-md font-bold py-3 px-4'>Rating</p>
            <p className={`${getRatingColor(companyList?.data?.data?.[0].rating)} rounded text-right py-3 px-4`}>{companyList?.data?.data?.[0].rating}</p>
        </div> */}
      </div>
      <div className='flex items-center mb-4 border rounded bg-[#f9f9f9] shadow'>
        <div className='flex p-3 md:py-4 items-center justify-between md:justify-start w-full'>
          <h1 className='font-bold text-lg'>Initial Report</h1>
          <Link to={`/update/${companyList?.data?.data?.[0].document_id}`}><p className='py-1 px-3 bg-blue-100 border border-blue-500 text-blue-500 text-xs font-semibold ml-4 shadow-sm rounded-full'>View</p></Link>
        </div>
      </div>
      <div className='flex flex-col'>

        {
          updatesList?.data?.data?.length !== 0 ? (
            <>
              <div className='flex items-center mb-4 justify-between'>
                <h1 className='font-bold text-lg'>Updates</h1>
              </div>
              {
                updatesList?.data?.data?.map((it) => {
                  return (
                    <div
                      key={it.id}
                      onClick={() => navigate(`/update/${it.id}`)}
                      className='flex flex-col md:flex-row md:items-center md:justify-between px-4 py-3 border rounded-md mb-3 bg-[#f9f9f9] shadow hover:cursor-pointer'
                    >
                      <div className='flex'>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-4">
                            <path fillRule="evenodd" d="M4.5 2A1.5 1.5 0 003 3.5v13A1.5 1.5 0 004.5 18h11a1.5 1.5 0 001.5-1.5V7.621a1.5 1.5 0 00-.44-1.06l-4.12-4.122A1.5 1.5 0 0011.378 2H4.5zm2.25 8.5a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zm0 3a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" clipRule="evenodd" />
                          </svg> */}
                        <p className='text-md hover:underline underline-offset-1 hover:cursor-pointer font-semibold'>
                          {it.document_name}
                        </p>
                      </div>
                      <div className='flex items-center mt-2 md:mt-0'>
                        <p className={`text-xs px-3 py-1 rounded-full md:ml-auto ${getUpdateTypeColor(it?.type)}`}>{it?.type}</p>
                        <p className='text-xs ml-4 flex'>({it.date})</p>
                      </div>
                    </div>
                  )
                })
              }
            </>
          ) : (
            <div className='flex justify-center items-center min-h-[15rem] rounded'>
              <p className="text-black-400 font-bold">No updates found...</p>
            </div>
          )
        }
      </div>
    </UserLayout >
  )
}

export default CompanyProfileUser