import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../components/paginate/index";
import UserLayout from "../../../components/Layout/UserLayout";
import useGetData from "../../../hooks/useGetData";
import Moment from "react-moment";
import Cookies from "universal-cookie";

const getRatingColor = (rating) => {
  if (rating === "Exit") {
    return "bg-red-100 text-red-500 border border-red-500 shadow";
  } else if (rating === "Buy") {
    return "bg-green-50 text-green-500 border border-green-500 shadow";
  } else if (rating === "Hold") {
    return "bg-blue-100 text-blue-500 border border-blue-500 shadow";
  }
};

const OrderByComponent = ({ visible, order }) => {
  return (
    <>
      {order === "desc" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={`${visible ? "" : "hidden hover:block"} ml-1 w-5 h-5`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={`${visible ? "" : "hidden hover:block"} ml-1 w-5 h-5`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
          />
        </svg>
      )}
    </>
  );
};

const companyInitialState = {
  limit: 10,
  pageNo: 0,
  searchQuery: "",
  type: "all",
};

const UserBoard = () => {
  const cookies = new Cookies();
  let role = cookies.get("_role");
  console.log(role);
  let params = useParams();
  const navigate = useNavigate();
  const { getFormData, setGetFormData, handleGetDataList } = useGetData(
    companyInitialState,
    "getCompanyMarketPriceList",
    params.type
  );

  const subHeadRoute = (type) => {
    setGetFormData({ ...companyInitialState, type });
  };

  const handleSearchVal = (e) => {
    let value = e.target.value;
    let data = {
      ...getFormData,
      [e.target.name]: value,
    };
    setGetFormData(data);
  };

  const onOrderhandle = (data) => {
    if (getFormData.order === data) {
      if (getFormData.order_method === "desc") {
        setGetFormData({
          ...getFormData,
          order_method: "asc",
        });
      } else if (getFormData.order_method === "asc") {
        setGetFormData({
          ...getFormData,
          order_method: "desc",
        });
      }
    } else {
      setGetFormData({
        ...getFormData,
        order: data,
        order_method: "desc",
      });
    }
  };

  const handlePageClick = (val) => {
    let data = {
      ...getFormData,
      pageNo: val.selected,
    };
    setGetFormData(data);
  };
  const [isIPhone, setIsIPhone] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsIPhone(/iPhone/i.test(userAgent));
  }, []);
  return (
    <UserLayout>
      <div className="flex h-max mb-6 w-full items-center border-b-2 justify-between md:justify-start">
        <p
          onClick={() => subHeadRoute("all")}
          end={true}
          className={`mr-0 md:mr-[7.5px] p-3 hover:cursor-pointer border-b-4 ${
            getFormData.type === "all"
              ? "text-[#ef756e] border-b-red-400"
              : "text-black hover:text-[#ef756e] border-transparent"
          }`}
        >
          All Stocks
        </p>
        <p
          onClick={() => subHeadRoute("buy")}
          end={true}
          className={`mr-0 md:mr-[7.5px] p-3 hover:cursor-pointer border-b-4 ${
            getFormData.type === "buy"
              ? "text-[#ef756e] border-b-red-400"
              : "text-black hover:text-[#ef756e] border-transparent"
          }`}
        >
          Buy
        </p>
        <p
          onClick={() => subHeadRoute("hold")}
          end={true}
          className={`mr-0 md:mr-[7.5px] p-3 hover:cursor-pointer border-b-4 ${
            getFormData.type === "hold"
              ? "text-[#ef756e] border-b-red-400"
              : "text-black hover:text-[#ef756e] border-transparent"
          }`}
        >
          Hold
        </p>
        <p
          onClick={() => subHeadRoute("exit")}
          end={true}
          className={`mr-0 md:mr-[7.5px] p-3 hover:cursor-pointer border-b-4 ${
            getFormData.type === "exit"
              ? "text-[#ef756e] border-b-red-400"
              : "text-black hover:text-[#ef756e] border-transparent"
          }`}
        >
          Exit
        </p>
      </div>
      <div className="mb-4 w-full">
        <input
          onChange={handleSearchVal}
          name="searchQuery"
          className="w-full md:w-[40%] border drop-shadow shadow-sm focus:border-white focus:outline-none focus:ring-1 focus:ring-red-300 rounded px-3 py-2"
          type="text"
          placeholder="Search stocks..."
        />
      </div>
      {/* drop-shadow-md */}
      <div className="wrapper overflow-x-auto  shadow-sm relative border sm:rounded-lg mb-4">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-[11px] text-red-700 uppercase bg-red-100">
            <tr>
              <th
                scope="col"
                className="sticky-col first-col outline outline-1 outline-slate-200 bg-red-100 py-1 px-4 text-left"
              >
                Stock Name
              </th>
              <th
                scope="col"
                className="py-1 px-4 text-center"
                onClick={() => onOrderhandle("record_date")}
              >
                <div className="flex justify-center hover:cursor-pointer">
                  <span>Reco Date</span>
                  <OrderByComponent
                    visible={getFormData.order === "record_date"}
                    order={getFormData.order_method}
                  />
                </div>
              </th>
              <th scope="col" className="py-1 px-3 text-center">
                Reco Price
              </th>
              <th
                scope="col"
                className="py-1 px-3 text-center"
                onClick={() => onOrderhandle("current_price")}
              >
                <div className="flex justify-center hover:cursor-pointer">
                  <span>Current Price </span>
                  <OrderByComponent
                    visible={getFormData.order === "current_price"}
                    order={getFormData.order_method}
                  />
                </div>
              </th>
              <th
                scope="col"
                className="py-1 px-4 text-center"
                onClick={() => onOrderhandle("dividend")}
              >
                <div className=" flex justify-center hover:cursor-pointer">
                  <p>
                    {`Current Price +`}
                    <br />
                    {`Dividend`}
                  </p>
                  <OrderByComponent
                    visible={getFormData.order === "dividend"}
                    order={getFormData.order_method}
                  />
                </div>
              </th>
              <th
                scope="col"
                className="py-1 px-3 text-center"
                onClick={() => onOrderhandle("gain_and_loss")}
              >
                <div className="flex justify-center  hover:cursor-pointer">
                  <span>% Gain / Loss </span>
                  <OrderByComponent
                    visible={getFormData.order === "gain_and_loss"}
                    order={getFormData.order_method}
                  />
                </div>
              </th>
              <th scope="col" className="py-1 px-3 text-center">
                Rating
              </th>
              <th scope="col" className="py-1 px-3 text-center">
                Sizing Guide
              </th>
              <th
                scope="col"
                className="py-1 px-3 text-center"
                onClick={() => onOrderhandle("current_market_cap")}
              >
                <div className="flex justify-center hover:cursor-pointer">
                  <span>
                    Current Market
                    <br /> Cap(Rs Crs){" "}
                  </span>
                  <OrderByComponent
                    visible={getFormData.order === "current_market_cap"}
                    order={getFormData.order_method}
                  />
                </div>
              </th>
              <th scope="col" className="py-1 px-3 text-center">
                Industry
              </th>
            </tr>
          </thead>
          <tbody>
            {handleGetDataList?.isLoading ? (
              <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                <td className="py-4 px-7 text-center" colSpan={9}>
                  loading...
                </td>
              </tr>
            ) : handleGetDataList?.data?.data?.length !== 0 ? (
              handleGetDataList?.data?.data?.map((c, idx) => {
                const {
                  id,
                  company_name,
                  short_code,
                  rating,
                  sizing_guide,
                  category_name,
                  record_date,
                  reco_price,
                  current_price,
                  current_market_cap,
                  gain_and_loss,
                  dividend,
                } = c;
                const { threshold } =
                  handleGetDataList?.data?.threshold_data || {};
                // const handleLinkClick = (e) => {
                //   e.preventDefault();
                //   window.open(`/stocks/${id}`, "_blank");
                // };
                const openLinkInNewTab = () => {
                  const url = "/stocks/" + id;
                  const newWindow = window.open(url, "_blank");
                  if (newWindow) newWindow.opener = null; // Prevent security risk
                };
              
                return (
                  <tr
                    className={`${
                      idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"
                    } border-b hover:cursor-pointer hover:bg-gray-50`}
                    key={id}
                  >
                    {company_name === "" ? (
                      <th
                        scope="row"
                        style={{ color: "rgb(239 68 68)" }}
                        className={`sticky-col first-col w-max-36 outline outline-1 outline-slate-200 ${
                          idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"
                        } py-1 px-4 font-medium text-red-500 whitespace-nowrap text-white ${
                          isIPhone ? "" : "blur-sm"
                        } text-[11px] hover:underline`}
                        onClick={() => navigate("/membership")}
                      >
                        Upgrade Your Plan
                      </th>
                    ) : (
                      <th
                        scope="row"
                        className={`sticky-col first-col w-max-36 outline outline-1 outline-slate-200 ${
                          idx % 2 === 0 ? "bg-white" : "bg-[#f9f9f9]"
                        } py-1 px-4 font-medium text-gray-900 whitespace-nowrap   text-[11px] xl:text-[14px]`}
                      >
                        <div className=" flex items-center">
                          <a
                            href={"/stocks/" + id}
                            target="blank"
                            rel="noopener noreferrer"
                            className="flex hover:underline"
                          >
                            {short_code ? short_code : company_name}
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-3 h-3 ml-1 relative top-[2px]  stroke-blue-600"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                            />
                          </svg>
                          </a>
                          
                        </div>
                      </th>
                    )}
                    {company_name === "" ? (
                      <td
                        className={`py-1 px-4 text-center  text-[11px] xl:text-[14px] ${
                          isIPhone ? "" : "blur-sm"
                        }`}
                      >
                        Upgrade
                      </td>
                    ) : (
                      <td className="py-1 px-4 text-center  text-[11px] xl:text-[14px]">
                        {/* {record_date} */}
                        {record_date ? (
                          <Moment unix format="DD/MM/YYYY" date={record_date} />
                        ) : (
                          "--"
                        )}
                      </td>
                    )}
                    {company_name === "" ? (
                      <td
                        className={`py-3 px-4 text-center  text-[11px] xl:text-[14px] ${
                          isIPhone ? "" : "blur-sm"
                        }`}
                      >
                        Upgrade
                      </td>
                    ) : (
                      <td className="py-1 px-4 text-center  text-[11px] xl:text-[14px]">
                        {reco_price}
                      </td>
                    )}
                    {current_price === "" ? (
                      <td
                        className={`py-3 px-4 text-center  text-[11px] xl:text-[14px] ${
                          isIPhone ? "" : "blur-sm"
                        }`}
                      >
                        Upgrade
                      </td>
                    ) : (
                      <td className="py-1 px-4 text-center  text-[11px] xl:text-[14px]">
                        {current_price}
                      </td>
                    )}
                    {gain_and_loss === "" ? (
                      <td
                        className={`py-1 px-4 text-center  text-[11px] xl:text-[14px] ${
                          isIPhone ? "" : "blur-sm"
                        }`}
                      >
                        Upgrade
                      </td>
                    ) : (
                      <td
                        className={`py-1 px-4  text-center  text-[11px] xl:text-[14px] `}
                      >
                        {dividend || "0"}
                      </td>
                    )}
                    {gain_and_loss === "" ? (
                      <td
                        className={`py-1 px-4  text-center  text-[11px] xl:text-[14px] ${
                          isIPhone ? "" : "blur-sm"
                        }`}
                      >
                        Upgrade
                      </td>
                    ) : (
                      // scale-up-down
                      <td
                        className={`py-1 px-4  text-center  text-[11px] xl:text-[14px] ${
                          parseInt(gain_and_loss) > 0
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {gain_and_loss}
                      </td>
                    )}

                    {company_name === "" ? (
                      <>
                        <td
                          className={`py-1 px-4 text-center  text-[11px] xl:text-[14px] ${
                            isIPhone ? "" : "blur-sm"
                          }`}
                        >
                          Upgrade
                        </td>
                        <td
                          className={`py-1 px-4 text-center  text-[11px] xl:text-[14px] ${
                            isIPhone ? "" : "blur-sm"
                          }`}
                        >
                          Upgrade
                        </td>
                      </>
                    ) : (
                      <>
                        <td className="py-1 px-4 text-center  text-[11px] xl:text-[14px]">
                          <p
                            className={`px-4 py-1 ${getRatingColor(
                              rating
                            )} rounded-md w-fit text-center m-auto`}
                          >
                            {rating}
                          </p>
                        </td>
                        <td className="py-1 px-4 text-center  text-[11px] xl:text-[14px]">
                          {sizing_guide}
                        </td>
                      </>
                    )}
                    {current_price === "" ? (
                      <td
                        className={`py-3 px-4 text-center  text-[11px] xl:text-[14px] ${
                          isIPhone ? "" : "blur-sm"
                        }`}
                      >
                        Upgrade
                      </td>
                    ) : (
                      <td className="py-1 px-4 text-center  text-[11px] xl:text-[14px]">
                        {parseInt(current_market_cap).toLocaleString("en-US")}
                      </td>
                    )}
                    {category_name === "" ? (
                      <td
                        className={`py-3 px-4 text-center  text-[11px] xl:text-[14px] ${
                          isIPhone ? "" : "blur-sm"
                        }`}
                      >
                        Upgrade
                      </td>
                    ) : (
                      <td className="py-1 px-4 text-center  text-[11px] xl:text-[14px]">
                        {category_name}
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                <td className="py-4 px-7 text-center" colSpan={10}>
                  No stocks found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!handleGetDataList?.isLoading &&
        handleGetDataList?.data?.data?.length !== 0 && (
          <p className="text-red-700 text-[10px] font-semibold italic">
            * Current Price is as per{" "}
            <Moment format="DD/MM/yyyy" unix>
              {handleGetDataList?.data?.price_fetched_on}
            </Moment>{" "}
            closing price
          </p>
        )}
      <div className=" my-7">
        {!handleGetDataList?.isLoading && (
          <Pagination
            onPageChange={handlePageClick}
            pageCount={Math.ceil(
              handleGetDataList?.data?.total_count / getFormData.limit
            )}
            pageNo={handleGetDataList?.data?.page_no}
          />
        )}
      </div>
    </UserLayout>
  );
};

export default UserBoard;
