import React, { useState } from "react";
import Layout from "../../../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import DeleteGeneral from "./DeleteGeneral";
import { Button } from "@mui/material";
import useGetData from "../../../hooks/useGetData";
import { Toaster } from "react-hot-toast";


const generalInitialState = {
  limit: 10,
  pageNo: 0,
  searchQuery: '',
  order: '',
  order_method: ''
}
const General = () => {
  const navigate = useNavigate();
  const { getFormData, setGetFormData, handleGetDataList } = useGetData(generalInitialState, 'getGeneralUploadList')
  const [isOpenDeleteUpdateModal, setIsOpenDeleteUpdateModal] = useState(false);
  const [deleteUpdateData, setDeleteUpdateData] = useState(null);
  const deleteUpdates = (data) => {
    setDeleteUpdateData(data);
    setIsOpenDeleteUpdateModal(true);
  };
  return (
    <Layout>
      <Toaster />
      <div className=" flex justify-end mb-4">
        <Link to={'/add-general-and-stock-talk'} >
          <p className='h-max w-max rounded-md px-3 py-2 bg-[#ffebea] text-[#fe100e] hover:cursor-pointer'>Add document</p>
        </Link>
      </div>
      <div>
        {handleGetDataList?.isLoading ? <p className=" text-center">Loading...</p>
          : <>
            {handleGetDataList?.data?.total_count === 0
              ? <p className=" text-center">No Document found</p>
              : <>
                {handleGetDataList?.data?.data?.map((item, idx) => {
                  const { title, report_type, share, download, id } = item || {}
                  return <div className="flex items-center px-4 py-3 border rounded-md mb-3 bg-[#f9f9f9] shadow justify-between" key={idx}>
                    <div className="flex items-center">
                      <Link
                        to={"/"}
                        className="text-md hover:underline underline-offset-1 hover:cursor-pointer font-semibold"
                      >
                        {title}
                      </Link>
                    </div>
                    <div className="flex items-center">
                      {/* <p className="py-1 px-3 bg-amber-100 border border-amber-500 text-amber-500 text-xs font-semibold ml-4 rounded-full shadow-sm">
                        Shareable
                      </p>
                      <p className="py-1 px-3 bg-amber-100 border border-amber-500 text-amber-500 text-xs font-semibold ml-4 rounded-full shadow-sm">
                        Downloadable
                      </p> */}
                      {
                        share === 'true' ? (
                          <p className='py-1 px-3 bg-amber-100 border border-amber-500 text-amber-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Shareable</p>
                        ) : (
                          <p className='py-1 px-3 bg-gray-100 border border-gray-500 text-gray-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Not Shareable</p>
                        )
                      }
                      {
                        download === 'true' ? (
                          <p className='py-1 px-3 bg-amber-100 border border-amber-500 text-amber-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Downloadable</p>
                        ) : (
                          <p className='py-1 px-3 bg-gray-100 border border-gray-500 text-gray-500 text-xs font-semibold ml-4 rounded-full shadow-sm'>Not Downloadable</p>
                        )
                      }
                      {/* <p className={`text-xs px-3 py-1 rounded-full ml-4 ${getDocumentTypeColor(it.document_type)}`}>{it.document_type}</p>
              <p className='text-xs ml-4 flex'>({it.date})</p> */}
                      {/* location.pathname + '/updates/edit/' + it.id */}
                      <svg
                        onClick={() => navigate(`/edit-general-and-stock-talk/${id}`)}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer "
                      >
                        <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                        <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                      </svg>
                      <svg
                        onClick={() => deleteUpdates(item)}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5 ml-4 hover:mt-[-2px] hover:cursor-pointer text-red-600"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                })}
              </>}
          </>}
      </div>
      <DeleteGeneral
        open={isOpenDeleteUpdateModal}
        handleClose={() => setIsOpenDeleteUpdateModal(false)}
        data={deleteUpdateData}
      />
    </Layout>
  );
};

export default General;
