import React from 'react'
import useCreateOrder from '../../hooks/purchase/useCreateOrder';
import { RAZKEY } from '../../api/axios';
import useVerifyOrder from '../../hooks/purchase/useVerifyOrder';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/logo4.png'

const Payment = () => {
    let navigate = useNavigate()
    const { handleVerifyOrder } = useVerifyOrder({
        onSuccess: (res) => {
            // console.log(res);
            if (res.status === 200) {
               
                navigate('/payment/success', { replace: true })
            }
        },
        onError: (err) => {
            // console.log(err);
            
            navigate('/payment/failure', { replace: true })
        }
    })
    const { handleCreateOrder } = useCreateOrder({
        onSuccess: (res) => {
            const options = {
                key: RAZKEY,
                name: "Capital Ideas Analytics",
                description: "Capital Ideas Analytics",
                order_id: res.razorpayOrderId,
                image: logo,
                handler: async (response) => {
                    const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response
                    let data = {
                        razorpay_payment_id,
                        razorpay_order_id,
                        razorpay_signature
                    }
                    handleVerifyOrder.mutate(data)
                },
                theme: {
                    color: "#2172ef",
                },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    })

    const handleCreatePost = () => {
        let data = {
            subscription_id: 1,
            payment_method: "ONLINE"
        }
        handleCreateOrder.mutate(data)
    }
  return (
    <div className='text-center mt-4'>
        <button onClick={handleCreatePost} className=' bg-black text-white px-10 py-2 rounded border border-gray-300 text-sm uppercase tracking-widest font-semibold'>Pay online</button>
    </div>
  )
}

export default Payment