import ReactPaginate from 'react-paginate';
import './paginate.css'
function Pagination({ pageCount, onPageChange, pageNo }) {
    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={onPageChange}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            containerClassName='flex items-center justify-center w-max rounded-sm'
            pageLinkClassName='pageNumber'
            previousLinkClassName='pageNumber'
            nextLinkClassName='pageNumber nextPage'
            activeLinkClassName='selectedPageNumber'
            disabledClassName='lastPage'
            disabledLinkClassName='lastPage'
            forcePage={parseInt(pageNo)}
        />
    )
}

export default Pagination