import React, { useRef, useState } from 'react'
import Layout from '../../../../components/Layout'
import { useNavigate, useParams } from 'react-router-dom'
import useAddData from '../../../../hooks/useAddData'
import useGetData from '../../../../hooks/useGetData'
import EditorRC from '../../../../components/Editor/EditorRc'
import FormElement from '../../../../components/Form/FormElement'
import { DOCUMENT_DETAILS } from '../companyInputs'
import Loader from '../../../../components/Loader'
import { Toaster } from 'react-hot-toast'

const updates = {
  document_name: '',
  document_type: '',
  report_type: '',
  blog: '',
  share: '',
  download: '',
  document_file: null,
  document_file_name: '',
  upload_type_id: ''
}

const generalInitialState = {
  screen:'company'
}

const EditUpdates = () => {
  const navigate = useNavigate()
  const editorRef = useRef(null)
  let params = useParams();
  const [updatesData, setUpdatesData] = useState(updates)
  const [updatesTypeOptions, setUpdateTypeOptions] = useState([])

  const handleIndividualUpdateSuccess = (data) => {
    const temp = data.data[0]
    setUpdatesData({ ...temp })
  }
  const { handleGetDataList: getUpdateData } = useGetData({ document_id: params.updateId }, 'getIndividualDocument', handleIndividualUpdateSuccess)
  const { setDataToServer } = useAddData('/editCompanyDocument', () => { }, 'getCompanyUploadDocumentList')

  const handleUpdateTypeSuccess = (data) => {
    let temp = data.data
    let tempArr = []
    temp.forEach(it => {
      let data = {
        value: it.upload_type_id,
        name: it.type
      }
      tempArr.push(data)
    });
    setUpdateTypeOptions(tempArr)
  }
  const { handleGetDataList } = useGetData(generalInitialState, 'getUpdateType', handleUpdateTypeSuccess)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    let data = {}
    if (updatesData['report_type'] === 'BLOG') {
      data = {
        ...updatesData,
        company_id: params.companyId,
        document_file: null,
        document_file_name: null,
        blog: editorRef.current.getContent()
      }
    } else {
      data = {
        ...updatesData,
        company_id: params.companyId,
        blog: null
      }
    }
    setDataToServer.mutate(data)
  }

  const handleSetFileData = (e) => {
    let value = e.target.files[0];
    let data = {
      ...updatesData,
      document_file: value,
      document_file_name: e.target.files[0].name
    }
    setUpdatesData(data);
  }

  const handleSetFormData = (e) => {
    let value = e.target.value;
    let data = {
      ...updatesData,
      [e.target.name]: value
    }
    setUpdatesData(data);
  }

  return (
    <>
      <Layout>
        <div className='flex w-max mb-6 items-center hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mt-[-2px]">
            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
          </svg>
          <p className='ml-1'>Company Profile</p>
        </div>
        <h1 className='text-xl font-bold'>Edit Updates</h1>
        <form onSubmit={handleFormSubmit}>
          <div className='grid grid-cols-2 auto-rows-auto gap-6 gap-x-6 mt-6'>
            {
              DOCUMENT_DETAILS.map((input, idx) => {
                const { name } = input;
                return <FormElement
                  key={idx}
                  {...input}
                  value={updatesData[name]}
                  onChange={handleSetFormData}
                />
              })
            }
            <FormElement
              name="upload_type_id"
              placeholder="Enter Update Type"
              label="Update Type"
              type="text"
              element="select"
              value={updatesData['upload_type_id']}
              optionValue={updatesTypeOptions}
              onChange={handleSetFormData}
              required={true}
              errorMessage='This Field Cannot Be Empty !'
            />
            {
              (updatesData['report_type'] === "PDF") ?
                <div>
                  <label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Report Document </label>
                  <input
                    name="report_file"
                    placeholder='Enter Category'
                    type='file'
                    onChange={handleSetFileData}
                    // value={companyData['report_file']}
                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                  />
                </div> : null
            }
          </div>
          {
            updatesData['report_type'] === "BLOG" && (
              <div className="mt-6">
                <label htmlFor="category_search" className='block text-gray-700 text-sm font-bold mb-2'>Blog </label>
                <EditorRC value={updatesData['blog']} editorRef={editorRef} />
              </div>
            )
          }

          <button type='submit' className='h-max mt-6 w-max rounded-md px-3 py-2 text-white bg-[#fe100e] hover:cursor-pointer flex items-center'>Save {setDataToServer.isLoading && <Loader isInline={true} />}</button>
        </form>
      </Layout>
      <Toaster position='top-right' />
    </>
  )
}

export default EditUpdates