import React from 'react';
import { BiSolidErrorAlt } from "react-icons/bi";
import { Button, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Failure = () => {
    const navigate = useNavigate()
  return (
    <div className="page-width items-center  flex flex-col  lg:mt-40 lg:mb-30">
    <BiSolidErrorAlt className="text-red-700 w-24 mx-auto h-24 p-2" />
    <h2 className="hero_head_title w-fit pt-3 lg:pb-0 mx-auto text-2xl lg:text-3xl">
      Payment failed
    </h2>
    <p className="text-center font-semibold lg:text-lg mt-4">
      Please try again
      
    </p>
    <button
      href="/"
      className=" bg-black mt-3 text-white px-10 py-2 rounded border border-gray-300 text-sm uppercase tracking-widest font-semibold"
      onClick={() => navigate('/membership', { replace: true })}
    >
      Return to Payment
    </button>
    </div>
  )
}

export default Failure