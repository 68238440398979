import { QueryClient } from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react'
import { Link, Navigate, createSearchParams, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import UserLayout from '../../../components/Layout/UserLayout';
import XMLParser from 'react-xml-parser';
import ReactHtmlParser from 'react-html-parser';
import TwitterContainer from '../../../components/TwitterContainer';
import Loader from '../../../components/Loader';
import useGetData from '../../../hooks/useGetData';
import Moment from 'react-moment';

const queryClient = new QueryClient()

const cookies = new Cookies;

const updatesInitialState = {
  limit: 5,
  pageNo: 0,
  searchQuery: '',
  upload_type_id: ''
}

const getUpdateTypeColor = (type) => {
  switch (type) {
    case "Earnings Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Company Update":
      return 'text-blue-600 border border-blue-600 bg-blue-100'
    case "Initial Report":
      return 'text-green-600 border border-green-600 bg-green-100'
    case "General Updates":
      return 'text-pink-600 border border-pink-600 bg-pink-100'
    case "Stocktalk":
      return 'text-yellow-600 border border-yellow-600 bg-yellow-100'
    default:
      return 'text-purple-600 border border-purple-600 bg-purple-100'
  }
}

const getDaysAgo = (date) => {
  let splitDate = date?.split('/')
  let formattedDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`

  var today = new Date();
  var createdOn = new Date(formattedDate);
  var msInDay = 24 * 60 * 60 * 1000;

  createdOn.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0)

  var diff = (+today - +createdOn) / msInDay

  if (+diff > 7) {
    diff = formattedDate
  } else {
    if (+diff > 1) {
      diff = diff + ' days ago'
    } else {
      diff = diff + ' day ago'
    }
  }

  return diff
}

const companyInitialState = {
  limit: 5,
  type: 'all'
}

const Home = () => {
  let token = cookies.get("_pn_token");
  const navigate = useNavigate()
  const [blogs, setBlogs] = useState([])
  const [selectedItem, setSelectedItem] = useState('All')
  const [updatesTypeOptions, setUpdateTypeOptions] = useState([])
  const [blogsLoading, setBlogsLoading] = useState(true)
  const { handleGetDataList: handleCompanyList } = useGetData(companyInitialState, 'getCompanyMarketPriceList')
  const { getFormData, setGetFormData, handleGetDataList: updatesList } = useGetData(updatesInitialState, 'getAllCompaniesDocument', () => { })

  const handleItemClick = (name, value) => {
    setSelectedItem(name)
    setGetFormData({ ...getFormData, upload_type_id: value })
  }

  const handleUpdateTypeSuccess = (data) => {
    let temp = data.data
    let tempArr = []
    temp.forEach(it => {
      let data = {
        value: it.upload_type_id,
        name: it.type
      }
      tempArr.push(data)
    });
    setUpdateTypeOptions(tempArr)
  }
  const { handleGetDataList } = useGetData({}, 'getUpdateType', handleUpdateTypeSuccess)

  const rssFeed = () => {
    const RSS_URL = `https://capitalideaz.in/feed/`;

    let headers = new Headers();

    fetch(RSS_URL, {
      method: "GET",
      mode: 'cors',
      headers: headers
    })
      .then(response => response.text())
      .then(data => new XMLParser().parseFromString(data))
      .then(d => {
        let child = d?.children[0]?.children
        let blogsContainer = []
        child.map((i) => {
          if (i.name === 'item') {
            let blogCon = i?.children
            let blog = {}
            blogCon.map((j) => {
              blog[j.name] = j.value
            })
            return blogsContainer.push(blog)
          }
        })
        setBlogs(blogsContainer.slice(0, 5))
      })
      .then(res => setBlogsLoading(false))
  }

  useEffect(() => {
    rssFeed()
  }, [])

  if (!token) {
    return <Navigate to="/signin" replace />;
  } else {
    let decoded = jwtDecode(token);
    if (decoded?.role === "admin") {
      return <Navigate to="/company-master" replace />;
    }
  }

  return (
    <div>
      <UserLayout>
        <div className='flex flex-col md:flex-row '>
          {/* updates container */}
          <div className='flex flex-col md:w-[50%] md:flex-1'>
            <div className=''>
              <p className='text-xl md:text-2xl font-semibold mb-4'>Recent Updates</p>
              <div className='rounded'>
                <div className='flex flex-wrap flex-row h-30 w-full right-0 bg-white z-10 mb-2 '>
                  <div key={'all'} className='hover:cursor-pointer' onClick={() => handleItemClick('All', '')}>
                    <p className={`rounded-full text-sm mr-2 px-2 md:px-4 py-1  ${selectedItem === 'All' ? 'text-red-600 border border-red-200 bg-red-100 font-semibold rounded-lg' : 'hover:text-red-600 hover:bg-red-50 border'}`}>All</p>
                  </div>
                  {
                    updatesTypeOptions.map((item) => {
                      const { name, value } = item
                      return (
                        <div key={name} className='hover:cursor-pointer mb-2' onClick={() => handleItemClick(name, value)}>
                          <p className={`rounded-full text-sm  mr-2 px-4 py-1  ${selectedItem === name ? 'text-red-600 border border-red-200 bg-red-100 font-semibold rounded-lg' : 'hover:text-red-600 hover:bg-red-50 border'}`}>{name}</p>
                        </div>
                      )
                    })
                  }
                </div>
                <div className='w-full h-max rounded-lg'>
                  {
                    updatesList?.isLoading ? (
                      <div className='flex-1'>
                        <Loader />
                      </div>
                    ) : (
                      updatesList?.data?.data?.length !== 0 ? (
                        updatesList?.data?.data?.map((it, idx) => {
                          const { type } = it
                          return (
                            <div
                              key={it.id}
                              className={`flex items-center p-4 bg-[#fcfcfc] shadow-sm border mb-2 rounded`}
                            >
                              <div className='flex-1 flex flex-col'>
                                  {it?.company_id
                                    ? 
                                <div className='flex items-center flex-1 space-x-2'> 
                                    <a
                                      href={it.document_id !== "" ? `/stocks/${it.company_id}` : '/membership'}
                                      target='_blank'
                                      className={`${it.document_id ? '' : 'text-red-600'} hover:cursor-pointer hover:underline font-semibold  space-x-1`}
                                    >
                                      {it.document_id !== "" ? it.company_name || it.document_name : 'Upgrade'}
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 ml-1 mb-1 inline stroke-blue-500">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                      </svg>

                                    </a>
                                </div>
                                    : ''}
                                  <p
                                     className={`
                                     ${it.document_id !== "" ? `${type === 'General Updates' || type === 'Stocktalk' ? 'hover:cursor-pointer hover:underline font-semibold  space-x-1' : 'text-blue-600 text-sm font-medium'} '`  : 'text-red-600 blur-sm'} 
                                          hover:underline underline-offset-1 hover:cursor-pointer
                                     `}
                                    onClick={() => {
                                      if (it.document_id !== "") {
                                        navigate(`/update/${it.document_id}`)
                                      } else {
                                        navigate('/membership')
                                      }
                                    }}
                                  >
                                    <span className=''>{it.document_id ? it.document_name : 'Upgrade now to see the document'}</span>
                                  </p>
                                  <p className={`${type === 'General Updates' || type === 'Stocktalk' ? 'text-blue-600 text-sm font-medium' : 'text-xs font-medium text-gray-400'}`}> 
                                <span className=''>{it.document_id ? it.description : 'Upgrade now to see the document'}</span>
                          </p>
                              </div>
                              <div className='flex flex-col items-end'>
                                <p className={`text-xs mb-1 md:mb-2 ${getUpdateTypeColor(type)} w-max h-max py-[0.2rem] px-2 rounded-full`}>{type}</p>
                                <p className='mr-2 text-xs text-slate-600'>
                                  {/* {it.document_date ? getDaysAgo(it.document_date) : ''}   */}
                                  <Moment unix format='DD/MM/YYYY' date={it.document_date} />
                                </p>
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div className='flex flex-1 justify-center items-center min-h-[15rem] rounded'>
                          <p className="text-black-400">No updates found...</p>
                        </div>
                      )
                    )
                  }
                </div>
              </div>
            </div>
            <div className='mt-4'>
              {/* blog container */}
              <div className='flex items-center justify-between'>
                <p className='text-xl md:text-2xl font-semibold mt-2 md:mt-0 mb-2'>Lighthouse Blog</p>
                <a className='text-xs bg-red-100 h-max text-red-600 py-1 px-3 mx-3 md:mx-5 rounded-full hover:underline' target="_blank" href={'https://capitalideaz.in/lighthouse/'}>Read All Posts</a>
              </div>
              <div className='flex w-full border rounded-lg overflow-x-auto p-2 md:p-2 shadow'>
                {
                  blogsLoading ? <Loader /> : (
                    blogs.map((b, idx) => {
                      const { title, category, description, guid, link, pubDate } = b
                      let dateObj = new Date(pubDate)
                      let date = dateObj.getDate()
                      let month = dateObj.getMonth() + 1
                      let year = dateObj.getFullYear()
                      return (
                        <div className={`p-2 w-max min-w-[16rem] border rounded bg-[#f9f9f9] mr-2`} key={title}>
                          <a className='font-semibold hover:underline' target="_blank" href={link}>{decodeURI(title)}</a>
                          {/* <p className="text-md text-gray-500 my-2">{description}</p> */}
                          <div className='flex items-center justify-between mt-2'>
                            <p className="text-xs text-gray-500">Published on: {`${date}/${month}/${year}`}</p>
                            <a className='text-xs text w-max rounded-full text-red-600 underline hover:cursor-pointer' target="_blank" href={link}>Read More</a>
                          </div>
                        </div>
                      )
                    })
                  )
                }
              </div>
              {/* {
                blogs.length > 0 && (
                  <div className='flex items-center justify-center px-3 md:px-10'>
                    <hr className='flex flex-1' />
                    <a className='text-xs bg-red-100 text-red-600 py-1 px-3 mx-3 md:mx-5 rounded-full hover:underline' target="_blank" href={'https://capitalideaz.in/lighthouse/'}>Read All Posts</a>
                    <hr className='flex flex-1' />
                  </div>
                )
              } */}
            </div>
          </div>
          <div className='w-full md:w-[18rem] md:ml-8 mt-6 md:mt-0'>
            <div className='flex flex-col border rounded-md shadow-sm lg:shadow-md bg-[#fcfcfc] mb-6'>
              <p className='px-3 py-2 border-b font-semibold'>Coverage</p>
              {
                handleCompanyList?.isLoading ? (
                  <Loader />
                ) : (
                  handleCompanyList?.data?.data?.map((item) => {
                    const { company_name, gain_and_loss, id } = item
                    return (
                      <div className='w-full flex justify-between items-top border-b'>
                        {
                          company_name ? (
                            <>
                              <p className='p-3 text-sm hover:cursor-pointer hover:underline underline-offset-1' onClick={() => navigate(`/stocks/${id}`)}>{company_name}</p>
                              <p className={`p-3 text-sm ${gain_and_loss?.includes('-') ? 'text-red-600' : 'text-green-600'}`}>{gain_and_loss}</p>
                            </>
                          ) : (
                            <>
                              <p className='p-3 text-sm text-red-500'>Upgrade</p>
                              <p className='p-3 text-sm text-red-500 blur-sm'>00%</p>
                            </>
                          )
                        }

                      </div>
                    )
                  })
                )
              }
            </div>
            <TwitterContainer css={"w-full md:w-[18rem] h-[20rem] mt-6 md:mt-0"} />
          </div>
        </div>
      </UserLayout>
    </div>
  )
}

export default Home