import Drawer from '@mui/material/Drawer';
import { HiArrowLeft } from "react-icons/hi2";
import useAddData from '../../../hooks/useAddData';
import FormElement from '../../../components/Form/FormElement';
import { useRef, useState } from 'react';
import { RxReload } from "react-icons/rx";
import EditorRC from '../../../components/Editor/EditorRc';


const AddBanner = ({
  open,
  onClose
}) => {
  const editorRef = useRef(null);
  const [bannerData, setBannerData] = useState({
    content: '',
    title: '',
    hidden_status: false,
    screen: 'ALL' // screen (ALL | HOME | DASHBOARD | UPDATES)
  })
  const onSuccess = () => {
    onClose()
    setBannerData({
      content: '',
      screen: 'ALL'
    })
  }
  const { setDataToServer } = useAddData('/banner_create', onSuccess, 'banner_listing')



  const handleSetFormData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBannerData(prev => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSubmitBanner = (e) => {
    e.preventDefault();
    let finalBannerData = {
      ...bannerData,
      content: editorRef.current.getContent()
    }
    setDataToServer.mutate(finalBannerData)
  }

  const handleChangeStatus = (e) => {
    setBannerData(prev => {
      return {
        ...prev,
        hidden_status: e.target.checked
      }
    })
  }
  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      <div className=' w-[700px] h-full'>
        <div className=' flex items-center space-x-3 border-b border-gray-300 py-3 px-3'>
          <HiArrowLeft className='w-7 h-7 cursor-pointer' onClick={onClose} />
          <p className=' font-semibold text-xl'>Banner</p>
        </div>
        <div className=' px-4 py-5'>
          <form onSubmit={handleSubmitBanner} className=' space-y-4'>
            <div>
              <FormElement
                label={'Title'}
                value={bannerData['title']}
                name='title'
                required={true}
                onChange={handleSetFormData}
                element='input'
              /> 
            </div>
            <div>
              <label htmlFor='Content' className='block text-gray-700 text-sm font-bold mb-2'>Content <span className='mandatory text-red-700'>*</span></label>
              <EditorRC editorRef={editorRef} />
            </div>
            <div className=' space-x-1 mt-2'>
              <input type="checkbox" id="myCheckbox" name="hidden_status" checked={bannerData['hidden_status']} onChange={handleChangeStatus} />
              <label for="myCheckbox" className=' font-semibold'>Disable</label>
            </div>
            <button disabled={setDataToServer.isLoading} className='h-max w-max rounded-md px-3 disabled:opacity-40 py-2 bg-[#ffebea] text-[#fe100e] hover:cursor-pointer mt-5 flex items-center'>
              {setDataToServer.isLoading && <RxReload className=' mr-2 animate-spin' />}
              <p >Save Banner</p>
            </button>
          </form>
        </div>
      </div>
    </Drawer>
  )
}

export default AddBanner