import React, { useState } from 'react'
// import { useTheme } from '@mui/material/styles';
// import {  DialogActions, DialogContent, useMediaQuery } from '@mui/material'
import { Dialog } from '@headlessui/react' 
import FormElement from '../../../components/Form/FormElement';
import useAddData from '../../../hooks/useAddData';

const ChangePassword = ({open,setOpen}) => {
  const [password,setPassword] = useState("")
  const { setDataToServer } = useAddData('/passwordReset', () => setOpen(false),'')
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setDataToServer.mutate({
      password
    })
  }

  return (
    <Dialog open={open} onClose={handleClose} className="fixed inset-0 z-10 overflow-y-auto flex justify-center items-center">
      <div className="fixed inset-0 bg-black/50" aria-hidden="true"></div>
        <Dialog.Panel className="w-[90%] md:w-[25rem] flex flex-col justify-center p-6 bg-white z-40 rounded-md">
          <Dialog.Title className='font-bold text-lg mb-4'>Change Password</Dialog.Title>
          <form onSubmit={handleFormSubmit} >
            <FormElement
              name="password"
              label="New Password"    
              value={password}
              type="password"
              element="input"
              required={true}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className='flex justify-end mt-4'>
              <button type='submit' className='py-2 px-3 w-max bg-red-500 text-white border-red-500 hover:cursor-pointer border rounded focus:none'>Save</button>
            </div>
          </form>
        </Dialog.Panel>
      
    </Dialog>
  )
}

export default ChangePassword