import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react'
import FormElement from '../../../components/Form/FormElement';
import useAddData from '../../../hooks/useAddData';


const durationList = [
  { name: '1 month', value: '1 month' },
  { name: '3 months', value: '3 months' },
  { name: '6 months', value: '6 months' },
  { name: '12 months', value: '12 months' },
]

const SUBSCRIPTION_INPUTS = [
  {
    name: 'subscription_type',
    placeholder: 'Enter Subscription Type',
    label: 'Subscription Name',
    type: 'text',
    element: 'input',
    required: true,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'bill_description',
    placeholder: 'Enter Bill Description',
    label: 'Bill Description',
    type: 'text',
    element: 'input',
    required: true,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'subscription_duration',
    placeholder: 'Enter Rating',
    label: 'Duration',
    type: 'text',
    element: 'select',
    optionValue: durationList,
    required: true,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'subscription_price',
    placeholder: 'Enter Price',
    label: 'Price',
    type: 'text',
    element: 'input',
    required: true,
    errorMessage: 'This Field Cannot Be Empty !',
  },
]

const AddSubscription = ({ open, setOpen }) => {
  const [updatesData, setUpdatesData] = useState({ category_name: '' });
  const { setDataToServer } = useAddData('/createSubscription', () => setOpen(false), 'getSubscriptionList')

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setDataToServer.mutate(updatesData)
  }

  const handleSetFormData = (e) => {
    e.preventDefault();
    let value = e.target.value
    let data = {
      ...updatesData,
      [e.target.name]: value
    }
    setUpdatesData(data);
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{ minWidth: '25%' }}>
      <form onSubmit={handleFormSubmit}>
        <DialogContent>
          <h2 className='font-bold mb-6'>Add Category</h2>
          <div className='grid grid-cols-1 auto-rows-auto gap-6 gap-x-6 min-w-[22rem]'>
            {
              SUBSCRIPTION_INPUTS.map((input, idx) => {
                const { name } = input;
                return <FormElement
                  key={idx}
                  {...input}
                  value={updatesData[name]}
                  onChange={handleSetFormData}
                />
              })
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: "#fe100e",
              ':hover': {
                bgcolor: "#ffebea"
              }
            }} onClick={handleClose}
          >Cancel</Button>
          <Button
            sx={{
              color: "#fe100e",
              ':hover': {
                bgcolor: "#ffebea"
              }
            }}
            type="submit"
          >Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddSubscription