import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormElement from '../../../components/Form/FormElement';
import useAddData from '../../../hooks/useAddData';
import { toast } from 'react-hot-toast'; 

const errorToast = (mes) => toast.success(mes);

const DeleteUpdate = ({open,setOpen,data}) => {
  const { setDataToServer } = useAddData('/deleteCompanyDocument', () => setOpen(false),'getCompanyUploadDocumentList')
  const [value,setValue] = useState('')
  const [updatesData,setUpdatesData] = useState(data)
  const handleClose = () => {
    setOpen(false);
  };

  const deleteUpdate = (e) => {
    e.preventDefault();
    if(value === "Delete"){
      alert("delete")
    }else{
      alert("please enter properly")
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if(value === "Delete"){
      setDataToServer.mutate(updatesData)
    }
  }
  
  return (
    <Dialog open={open} onClose={handleClose} sx={{ minWidth: '25%' }}>
      <form onSubmit={handleFormSubmit}>
        <DialogContent>
          <h1 className='font-bold text-lg mb-3'>Delete</h1>
          <p>This action cannot be undone. This will permanently delete the <span className='font-bold'>{data['document_name']}</span> file</p>
          <p className='mb-3'>Please enter <span className='font-bold'>Delete</span> to confirm</p>  
          <FormElement 
            value={value}
            type='text'
            element='input'
            onChange={(e) => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            sx={{ 
                color: "#fe100e",
                ':hover':{
                    bgcolor: "#ffebea"
                }
            }} onClick={handleClose}
          >Cancel</Button>
          <Button 
            sx={{ 
                color: "#fe100e",
                ':hover':{
                    bgcolor: "#ffebea"
                }
            }} 
            type="submit"
            disabled={value !== "Delete"? true:false}
          >Delete</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default DeleteUpdate