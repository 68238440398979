import React, { useState } from 'react'
import useGetData from '../../hooks/useGetData'

const initailState = {
  type: "",
  searchQuery: '',
  limit: 5,
  pageNo: 0
}

const EmailSelect = ({ selectAllChecked, subscriptionListChecked, selectedUsers, setSelectedUsers, handleRemoveUser }) => {
  const [search, setSearch] = useState("")
  const { getFormData, setGetFormData, handleGetDataList } = useGetData(initailState, 'getUserList')

  const handleSearchUser = (e) => {
    let value = e.target.value
    let data = {
      ...getFormData,
      searchQuery: value,

    }
    setGetFormData(data)
    setSearch(e.target.value)
  }

  const handleKeyDown = (e) => {
    // check if all or types are selected

    if (selectAllChecked || (Object.keys(subscriptionListChecked).length === 0 && subscriptionListChecked.constructor === Object)) {
      alert("App users are selected. Remove them to add custom users")
      return
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      let tempUsers = [...selectedUsers]
      tempUsers.push(e.target.value)
      setSelectedUsers(tempUsers)
      setSearch("")
      return
    }
  };

  const handleSearchUserSelect = (email) => {

    // check if all or types are selected


    let tempUsers = [...selectedUsers]
    tempUsers.push(email)
    setSelectedUsers(tempUsers)
    setSearch("")
  }

  return (
    <div>
      <div className=''>
        <input
          className='border w-full py-2 px-3 rounded-md focus:outline-none focus:border-gray-300 focus:shadow-sm text-sm'
          placeholder='Search...'
          type='text'
          value={search}
          autoComplete="off"
          onChange={handleSearchUser}
          onKeyDown={handleKeyDown}
        />
        {
          search.length !== 0 && (
            <div className='absolute mt-2 min-h-max max-h-[14rem] overflow-y-auto bg-white border shadow rounded-md w-[96%] scrollbar'>
              {
                handleGetDataList?.isLoading ? (
                  <div className='flex min-h-[5rem] items-center justify-center scrollbar'>
                    <p className='text-sm text-gray-400'>Loading...</p>
                  </div>
                ) : (
                  handleGetDataList?.data?.data?.length !== 0 ? (
                    handleGetDataList?.data?.data?.map((c) => {
                      const { username, email } = c
                      return (
                        <div key={username} className='w-full flex flex-col border-b px-5 py-3 hover:cursor-pointer' onClick={() => handleSearchUserSelect(email)}>
                          <p className='text-md font-semibold'>{username}</p>
                          <p className='text-sm'>{email}</p>
                        </div>
                      )
                    }
                    )) : (
                    <div className='flex min-h-[5rem] items-center justify-center'>
                      <p className='text-sm text-gray-400'>No Users Found...</p>
                    </div>
                  )
                )
              }
            </div>
          )
        }

      </div>

      <div className='max-h-[8rem] overflow-y-auto scrollbar'>
        {
          selectedUsers?.length === 0 ? (
            <div className='flex items-center h-[8rem] w-full justify-center'>
              <p className='text-gray-400 text-sm'>No Users Selected</p>
            </div>
          ) : (
            <div className='flex flex-wrap my-4'>
              {
                selectedUsers.map((item) => {
                  return (
                    <div key={item} className='flex mt-2 bg-red-100 items-center rounded-full py-1 px-2 mr-3 border border-red-400'>
                      <p className='text-sm text-red-700 '>{item}</p>
                      <button type='button' onClick={() => handleRemoveUser(item)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3 bg-transparent mb-[-3px] ml-1 text-red-700 hover:cursor-pointer">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default EmailSelect