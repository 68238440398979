import { Checkbox } from '@mui/material';
import React, { useEffect } from 'react'
import { useState } from 'react'
import useOutsideClick from '../../hooks/useOutsideClick';
import EmailSelect from './EmailSelect';


const UserSelect = ({
  selectedUsers,
  setSelectedUsers,
  subscriptionList,
  subscriptionListChecked, // usbscription list checked
  setSubcriptionListChecked,
  selectAllChecked, // all checked
  setSelectAllChecked,
  handleSelectAll,
  handleSelectType,
  handleRemoveUser
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const [showMenuType, setShowMenuType] = useState("TYPE") // USERS


  const handleClickOutside = () => {
    setShowMenu(false)
  }

  const ref = useOutsideClick(handleClickOutside);

  useEffect(() => {
    const subscriptionListObj = {}
    subscriptionList?.map((item) => {
      return subscriptionListObj[item.id] = { ...item, checked: false }
    })
    setSubcriptionListChecked(subscriptionListObj)
  }, [subscriptionList])

  return (
    <div className='block ' ref={ref}>
      <div
        className='flex max-w-[15rem] min-h-[2.6rem] h-max shadow appearance-none border rounded w-full py-2 px-3 text-sm font-semibold text-gray-700 focus:outline-none focus:shadow-outline hover:cursor-pointer'
        onClick={() => setShowMenu(!showMenu)}
      >
        <p className='mb-[-2px]'>
          Select Users
        </p>
        <div className={`flex items-center justify-center ml-auto ${showMenu ? 'rotate-180' : ''}`}>
          <div className='arrow-down hidden md:block'></div>
        </div>
      </div>
      {
        showMenu && (
          <div className='absolute bg-white border-2 shadow-2xl  max-h-[15rem] min-h w-[25rem] z-[1000] mt-2 rounded-md transition ease-in-out duration-75'>
            <div className='flex flex-row w-full hover:cursor-pointer'>
              <p onClick={() => setShowMenuType("TYPE")} className={`flex-1 py-3 text-center text-sm font-semibold ${showMenuType === 'TYPE' ? '' : 'bg-gray-100 border-b border-r rounded-br shadow-inner'}`}>Types</p>
              <p onClick={() => setShowMenuType("CUSTOM")} className={`flex-1 py-3 text-center text-sm font-semibold ${showMenuType === 'TYPE' ? 'bg-gray-100 rounded-bl rounded-tr border-l border-b shadow-inner' : ' '} `}>Custom</p>
            </div>
            {
              showMenuType === 'TYPE' ? (
                <div className='p-2 pr-6  overflow-y-auto'>
                  {/* Select All */}
                  <div className='flex items-center'>
                    <Checkbox
                      size="small"
                      checked={selectAllChecked}
                      onChange={(e) => handleSelectAll(e)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <p className='ml-2 text-sm'>Select All</p>
                  </div>
                  {/* select type */}
                  {subscriptionList?.length !== 0 && (
                    <div>
                      <p className='ml-3 text font-semibold my-2'>Select based on subscriptions</p>
                      {subscriptionList?.map((item) => {
                        const { subscription_type, id } = item
                        return (
                          <div key={id}>
                            <div className='flex items-center'>
                              <Checkbox
                                size="small"
                                checked={subscriptionListChecked[id]['checked']}
                                onChange={(e) => handleSelectType(e, id)}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <p className='ml-2 text-sm'>{subscription_type}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              ) : (
                <div className='p-2 overflow-y-auto'>
                  <EmailSelect
                    selectAllChecked={selectAllChecked}
                    subscriptionListChecked={subscriptionListChecked}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    handleRemoveUser={handleRemoveUser}
                  />
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default UserSelect