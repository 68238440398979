import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactGA from 'react-ga';
import SignIn from './pages/auth/SignIn';
import CompanyMaster from './pages/admin-pages/CompanyMaster';
import Users from './pages/admin-pages/Users';
import CompanyProfile from './pages/admin-pages/CompanyProfile';
import SignUp from './pages/auth/Signup'
import CategoryMaster from './pages/admin-pages/CategoryMaster';
import ProtectedRoutes from './routes/ProtectedRoutes';
import AddCompany from './pages/admin-pages/CompanyMaster/AddCompany';
import UserBoard from './pages/user-pages/UserBoard';
import CompanyProfileUser from './pages/user-pages/CompanyProfileUser';
import UserProfile from './pages/user-pages/UserProfile';
import InitialReport from './pages/InitialReport';
import EditCompany from './pages/admin-pages/CompanyMaster/EditCompany';
// import BlogReport from './pages/admin-pages/CompanyProfile/BlogReport';
// import PdfReport from './pages/admin-pages/CompanyProfile/PdfReport';
// import BlogReportUser from './pages/user-pages/CompanyProfileUser/BlogReportUser';
// import PdfReportUser from './pages/user-pages/CompanyProfileUser/PdfReportUser';
import SubscriptionMaster from './pages/admin-pages/SubscriptionMaster';
import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import UserGuide from './pages/StaticPages/UserGuide';
import Membership from './pages/StaticPages/Membership';
import UpdatesBoard from './pages/user-pages/UpdatesBoard/UpdatesBoard';
import EmailSender from './pages/admin-pages/EmailSender/EmailSender';
import Home from './pages/user-pages/Home/Home';
import Disclaimer from './pages/Disclaimer';
import AddDisclaimer from './pages/Disclaimer/AddDisclaimer';
import EditDisclaimer from './pages/Disclaimer/EditDisclaimer';
import CreateUpdates from './pages/admin-pages/CompanyProfile/Updates/CreateUpdates'
import EditUpdates from './pages/admin-pages/CompanyProfile/Updates/EditUpdates'
import ViewUpdates from './pages/admin-pages/CompanyProfile/Updates/ViewUpdates';
import ViewUpdatesUser from './pages/user-pages/CompanyProfileUser/ViewUpdatesUser';
import { useEffect } from 'react';
import Success from './pages/Status/Success';
import Failure from './pages/Status/Failure';
import Orders from './pages/sales/Orders';
import General from './pages/admin-pages/General';
import AddGeneral from './pages/admin-pages/General/AddGeneral';
import EditGeneral from './pages/admin-pages/General/EditGeneral';
import UserDetail from './pages/admin-pages/Users/UserDetail';
import AddBanner from './pages/admin-pages/banner/AddBanner';
import BannerListing from './pages/admin-pages/banner/BannerListing';


const TRACKING_ID = "G-NMN57YB4S5"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

const queryClient = new QueryClient()

const cookies = new Cookies;

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path='/company-master' exact element={<ProtectedRoutes UserType="admin"><CompanyMaster /></ProtectedRoutes>} />
          <Route path='/forgot-password' exact element={<ForgotPassword />} />
          <Route path='/reset-password' exact element={<ResetPassword />} />
          <Route path='/company-master/:companyId' exact element={<ProtectedRoutes UserType="admin"><CompanyProfile /></ProtectedRoutes>} />
          <Route path='/general-and-stock-talk' exact element={<ProtectedRoutes UserType="admin"><General /></ProtectedRoutes>} />
          <Route path='/add-general-and-stock-talk' exact element={<ProtectedRoutes UserType="admin"><AddGeneral /></ProtectedRoutes>} />
          <Route path='/edit-general-and-stock-talk/:id' exact element={<ProtectedRoutes UserType="admin"><EditGeneral /></ProtectedRoutes>} />
          {/* initial Report */}
          {/* <Route path='/company-master/:companyId/blog-report' exact element={<ProtectedRoutes UserType="admin"><BlogReport /></ProtectedRoutes>} />
          <Route path='/company-master/pdf-report' exact element={<ProtectedRoutes UserType="admin"><PdfReport /></ProtectedRoutes>} /> */}
          <Route path='/company-master/:companyId/edit' exact element={<ProtectedRoutes UserType="admin"><EditCompany /></ProtectedRoutes>} />
          <Route path='/company-master/add' exact element={<ProtectedRoutes UserType="admin"><AddCompany /></ProtectedRoutes>} />
          {/* <Route path='/company-master/:companyId/intial-report' exact element={<ProtectedRoutes UserType="admin"><InitialReport /></ProtectedRoutes>} /> */}
          {/* disclaimer */}
          <Route path='/disclaimer' exact element={<ProtectedRoutes UserType="admin"><Disclaimer /></ProtectedRoutes>} />
          <Route path='/disclaimer/add' exact element={<ProtectedRoutes UserType="admin"><AddDisclaimer /></ProtectedRoutes>} />
          <Route path='/disclaimer/:id/edit' exact element={<ProtectedRoutes UserType="admin"><EditDisclaimer /></ProtectedRoutes>} />
          {/* Updates */}
          <Route path='/company-master/:companyId/updates/add' exact element={<ProtectedRoutes UserType="admin"><CreateUpdates /></ProtectedRoutes>} />
          <Route path='/company-master/:companyId/updates/edit/:updateId' exact element={<ProtectedRoutes UserType="admin"><EditUpdates /></ProtectedRoutes>} />
          {/* This is the main parent for all admin: updates and initialreport */}
          <Route path='/company-master/:companyId/update/:documentId' exact element={<ProtectedRoutes UserType="admin"><ViewUpdates /></ProtectedRoutes>} />

          <Route path='/industry-master' exact element={<ProtectedRoutes UserType="admin"><CategoryMaster /></ProtectedRoutes>} />
          <Route path='/subscription-master' exact element={<ProtectedRoutes UserType="admin"><SubscriptionMaster /></ProtectedRoutes>} />
          <Route path='/users' exact element={<ProtectedRoutes UserType="admin"><Users /></ProtectedRoutes>} />
          <Route path='/user-detail/:id' exact element={<ProtectedRoutes UserType="admin"><UserDetail /></ProtectedRoutes>} />
          <Route path='/email-builder' exact element={<ProtectedRoutes UserType="admin"><EmailSender /></ProtectedRoutes>} />
          <Route path='/banners' exact element={<ProtectedRoutes UserType="admin"><BannerListing /></ProtectedRoutes>} />
          <Route path='/banners/add-banner' exact element={<ProtectedRoutes UserType="admin"><AddBanner /></ProtectedRoutes>} />
          <Route path='/signin' exact element={<SignIn />} />
          <Route path='/signup' exact element={<SignUp />} />
          <Route path='/stocks' exact element={<ProtectedRoutes UserType="members"><UserBoard /></ProtectedRoutes>} />
          <Route path='/stocks/:companyId' exact element={<ProtectedRoutes UserType="members"><CompanyProfileUser /></ProtectedRoutes>} />
          <Route path='/update/:updateId' exact element={<ProtectedRoutes UserType="members"><ViewUpdatesUser /></ProtectedRoutes>} />
          {/* <Route path='/stocks/:companyId/blog-report' exact element={<ProtectedRoutes UserType="members"><BlogReportUser /></ProtectedRoutes>} />
          <Route path='/stocks/:companyId/pdf-report' exact element={<PdfReportUser />} /> --- commented
          <Route path='/pdf-report' exact element={<ProtectedRoutes UserType="members"><PdfReportUser /></ProtectedRoutes>} /> */}
          <Route path='/profile' exact element={<ProtectedRoutes UserType="members"><UserProfile /></ProtectedRoutes>} />
          <Route path='/orders' exact element={<ProtectedRoutes UserType="members"><Orders /></ProtectedRoutes>} />
          <Route path='/user-guide' exact element={<ProtectedRoutes UserType="members"><UserGuide /></ProtectedRoutes>} />
          <Route path='/updates' exact element={<ProtectedRoutes UserType="members"><UpdatesBoard /></ProtectedRoutes>} />
          <Route path='/membership' exact element={<ProtectedRoutes UserType="members"><Membership /></ProtectedRoutes>} />
          <Route path='/payment/success' exact element={<ProtectedRoutes UserType="members"><Success /></ProtectedRoutes>} />
          <Route path='/payment/failure' exact element={<ProtectedRoutes UserType="members"><Failure /></ProtectedRoutes>} />
          <Route path="/" exact element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;