import axios from 'axios';

// export const BASE_URL = 'http://192.168.1.16/';
// export const BASE_URL = 'https://api.capitalideaz.in/';
export const BASE_URL = 'https://sapi.capitalideaz.in/';

// ------------- FRONT-END URL
// production
export const FRONT_END_URL = 'http://sdashboard.capitalideaz.in/'
// local
// export const FRONT_END_URL = 'http://localhost:3000/'
// staging
// export const FRONT_END_URL = 'http://sdashboard.capitalideaz.in/'


// ------------- MEDIA PATH URL
export const MEDIA_PATH = 'https://media.capitalideaz.in/stage/'
// export const MEDIA_PATH = 'https://media.capitalideaz.in/'

export const ReCAPTCHA_KEY = '6LfZWvMlAAAAAGzTF1pURFI0TD5uzl33PPu7GiP9'


// ~ NOTE For live site : rzp_live_gTuQmQIngoMXqY
// ~ NOTE For testing site : rzp_test_xZY1GBP0u70moU
export const RAZKEY = 'rzp_live_gTuQmQIngoMXqY'

export default axios.create({
    baseURL: BASE_URL,
    insecureHTTPParser: true
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    // withCredentials: true
});